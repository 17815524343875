import React from 'react';
import RootCauseAnalysisPro from './components/RootCauseAnalysisPro';
import { AssistantProvider } from './contexts/AssistantContext';
import { theme } from './styles/theme';
import './App.css';

function App() {
  return (
    <AssistantProvider>
      <div className={`min-h-screen bg-[${theme.colors.background.default}]`}>
        <RootCauseAnalysisPro />
      </div>
    </AssistantProvider>
  );
}

export default App;
