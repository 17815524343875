import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { BookOpen, ChevronDown, ChevronUp } from 'lucide-react';
import GuidelinesForWritingCAs from './GuidelinesForWritingCAs';
import CausalFactorTable from './CausalFactorTable';
import ReviewApproval from './ReviewApproval';
import { Card } from './common';
import { theme } from '../styles/theme';

const CorrectiveActionPlan = ({ projectId }) => {
  const [problemStatement, setProblemStatement] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [causalFactors, setCausalFactors] = useState({
    fiveWhys: null,
    fishbone: null
  });

  useEffect(() => {
    if (!projectId) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch problem statement
        const { data: problemData } = await supabase
          .from('problem_statements')
          .select('full_statement')
          .eq('project_id', projectId)
          .maybeSingle();

        setProblemStatement(problemData?.full_statement || '');

        if (problemData?.full_statement) {
          // Fetch Five Whys data
          const { data: whysData } = await supabase
            .from('five_whys_analysis')
            .select('whys')
            .eq('project_id', projectId)
            .maybeSingle();

          // Fetch Fishbone data
          const { data: fishboneData } = await supabase
            .from('fishbone_diagrams')
            .select('data')
            .eq('project_id', projectId)
            .maybeSingle();

          setCausalFactors({
            fiveWhys: whysData?.whys || null,
            fishbone: fishboneData?.data?.cause_classifications || null
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

  if (isLoading) {
    return (
      <div className="space-y-6">
        <h2 className="text-3xl font-bold" style={{ color: theme.colors.primary.main }}>
          Corrective Action Plan
        </h2>
        <div className="text-center py-4" style={{ color: theme.colors.text.secondary }}>Loading...</div>
      </div>
    );
  }

  if (!problemStatement) {
    return (
      <div className="space-y-6">
        <h2 className="text-3xl font-bold" style={{ color: theme.colors.primary.main }}>
          Corrective Action Plan
        </h2>
        <Card style={{ 
          backgroundColor: theme.colors.neutral.light,
          borderColor: theme.colors.border.main
        }}>
          <p style={{ color: theme.colors.text.primary }} className="font-medium">
            Problem not defined. Please complete the Problem Definition step first.
          </p>
        </Card>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-3xl font-bold" style={{ color: theme.colors.primary.main }}>
        Corrective Action Plan
      </h2>
      
      <Card className="p-6">
        <h3 className="text-xl font-semibold mb-4" style={{ color: theme.colors.primary.main }}>Problem Statement:</h3>
        <p className="leading-relaxed" style={{ color: theme.colors.text.secondary }}>{problemStatement}</p>
      </Card>

      <Card className="overflow-hidden">
        <button
          onClick={() => setShowGuidelines(!showGuidelines)}
          className="flex items-center justify-between w-full px-6 py-4 bg-white transition-all duration-200 group"
          style={{ 
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.colors.border.light
          }}
        >
          <div className="flex items-center space-x-3">
            <div className="p-2 rounded-lg text-white shadow-sm group-hover:shadow-md transition-all duration-200"
              style={{ backgroundColor: theme.colors.primary.main }}>
              <BookOpen size={20} />
            </div>
            <span className="font-semibold transition-colors duration-200"
              style={{ color: theme.colors.primary.main }}>
              Guidelines for Writing Corrective Actions
            </span>
          </div>
          {showGuidelines ? 
            <ChevronUp size={20} style={{ color: theme.colors.text.secondary }} /> : 
            <ChevronDown size={20} style={{ color: theme.colors.text.secondary }} />
          }
        </button>
        {showGuidelines && (
          <div className="p-6" style={{ 
            backgroundColor: theme.colors.background.paper,
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.colors.border.light
          }}>
            <GuidelinesForWritingCAs />
          </div>
        )}
      </Card>

      <CausalFactorTable 
        projectId={projectId}
        causalFactors={causalFactors}
      />

      <ReviewApproval projectId={projectId} />
    </div>
  );
};

export default CorrectiveActionPlan;
