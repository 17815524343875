import React from 'react';
import { commonStyles } from '../../styles/theme';

const Card = ({ 
  children, 
  className = '', 
  title,
  ...props 
}) => {
  return (
    <div className={`${commonStyles.card.base} ${className}`} {...props}>
      {title && (
        <div className="p-4 border-b border-[#E9E8E8]">
          <h3 className={`${commonStyles.heading.base} text-xl`}>{title}</h3>
        </div>
      )}
      <div className="p-4">
        {children}
      </div>
    </div>
  );
};

export default Card;
