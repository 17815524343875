import React, { useState, useEffect } from 'react';
import { PlusCircle, Save, RefreshCw, XCircle } from 'lucide-react';
import { supabase } from '../supabaseClient';
import { Button, Card, Input } from './common';
import { theme } from '../styles/theme';

const FiveWhyAnalysisTool = ({ projectId }) => {
  const [whys, setWhys] = useState([
    { because: '', isRoot: false, contributing: '', possible: '' }
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (projectId) {
      fetchAnalysis();
    }
  }, [projectId]);

  const fetchAnalysis = async () => {
    setIsLoading(true);
    setError('');
    try {
      const { data: duplicates } = await supabase
        .from('five_whys_analysis')
        .select('id')
        .eq('project_id', projectId);

      if (duplicates && duplicates.length > 1) {
        const sortedIds = duplicates
          .map(d => d.id)
          .sort()
          .slice(0, -1);

        await supabase
          .from('five_whys_analysis')
          .delete()
          .in('id', sortedIds);
      }

      const { data, error } = await supabase
        .from('five_whys_analysis')
        .select('*')
        .eq('project_id', projectId)
        .maybeSingle();

      if (error) throw error;

      if (data) {
        setWhys(data.whys || [{ because: '', isRoot: false, contributing: '', possible: '' }]);
      }
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setError('Failed to load analysis. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const addWhy = () => {
    if (whys.length < 10) {
      setWhys([...whys, { because: '', isRoot: false, contributing: '', possible: '' }]);
    }
  };

  const removeWhy = (index) => {
    const updatedWhys = whys.filter((_, i) => i !== index);
    setWhys(updatedWhys);
  };

  const updateWhy = (index, field, value) => {
    const updatedWhys = whys.map((why, i) => 
      i === index ? { ...why, [field]: value } : why
    );
    setWhys(updatedWhys);
  };

  const resetAnalysis = () => {
    setWhys([{ because: '', isRoot: false, contributing: '', possible: '' }]);
  };

  const saveAnalysis = async () => {
    if (!projectId) return;

    setSaveStatus('Saving...');
    try {
      await supabase
        .from('five_whys_analysis')
        .delete()
        .eq('project_id', projectId);

      const { error: insertError } = await supabase
        .from('five_whys_analysis')
        .insert({
          project_id: projectId,
          whys: whys
        });

      if (insertError) throw insertError;

      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving analysis:', error);
      setSaveStatus('Error saving');
      setTimeout(() => setSaveStatus(''), 3000);
    }
  };

  if (isLoading) {
    return <div className="text-center py-4 text-gray-600">Loading...</div>;
  }

  return (
    <Card className="p-6">
      <h1 className="text-3xl font-bold mb-6" style={{ color: theme.colors.primary.main }}>
        The Five Whys
      </h1>
      
      {error && (
        <div className="px-4 py-3 rounded-lg relative mb-4 bg-red-50 border border-red-200 text-red-600">
          {error}
        </div>
      )}

      <Card className="p-6 mb-6 overflow-x-auto">
        <table className="w-full min-w-max">
          <thead>
            <tr className="bg-gray-50">
              <th className="p-3 text-left font-semibold text-gray-700">Step</th>
              <th className="p-3 text-left font-semibold text-gray-700">This happened because:</th>
              <th className="p-3 text-left font-semibold text-gray-700">Is this the root cause?</th>
              <th className="p-3 text-left font-semibold text-gray-700">Contributing Causes</th>
              <th className="p-3 text-left font-semibold text-gray-700">Possible Causes</th>
              <th className="p-3 text-left font-semibold text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {whys.map((why, index) => (
              <tr key={index} className="border-b border-gray-200 hover:bg-gray-50 transition-colors">
                <td className="p-3 font-medium text-gray-700">Why {index + 1}</td>
                <td className="p-3">
                  <Input
                    value={why.because}
                    onChange={(e) => updateWhy(index, 'because', e.target.value)}
                  />
                </td>
                <td className="p-3">
                  <select
                    value={why.isRoot ? 'Yes' : 'No'}
                    onChange={(e) => updateWhy(index, 'isRoot', e.target.value === 'Yes')}
                    className="w-full px-4 py-2 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
                      focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
                      hover:border-indigo-300 transition-all duration-200"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </td>
                <td className="p-3">
                  <Input
                    value={why.contributing}
                    onChange={(e) => updateWhy(index, 'contributing', e.target.value)}
                    disabled={!why.isRoot}
                  />
                </td>
                <td className="p-3">
                  <Input
                    value={why.possible}
                    onChange={(e) => updateWhy(index, 'possible', e.target.value)}
                    disabled={!why.isRoot}
                  />
                </td>
                <td className="p-3">
                  <Button
                    onClick={() => removeWhy(index)}
                    variant="accent"
                    className="p-2"
                    title="Remove this why"
                  >
                    <XCircle size={16} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {whys.length < 10 && (
          <Button 
            onClick={addWhy} 
            variant="primary"
            className="mt-4"
            icon={PlusCircle}
          >
            Add Why
          </Button>
        )}
      </Card>

      <div className="flex justify-end space-x-4">
        <Button 
          onClick={resetAnalysis} 
          variant="secondary"
          icon={RefreshCw}
        >
          Reset
        </Button>
        <Button 
          onClick={saveAnalysis} 
          variant="primary"
          icon={Save}
        >
          Save
        </Button>
      </div>

      {saveStatus && (
        <div className={`mt-4 text-center px-4 py-2 rounded-lg border ${
          saveStatus.includes('Error')
            ? 'bg-red-50 border-red-200 text-red-600'
            : 'bg-emerald-50 border-emerald-200 text-emerald-600'
        }`}>
          {saveStatus}
        </div>
      )}
    </Card>
  );
};

export default FiveWhyAnalysisTool;
