import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const generateReport = async (data) => {
  const { projectData, problemStatement, fiveWhysCausalFactors, fishboneCausalFactors, correctiveActions, reviewData } = data;
  
  const doc = new jsPDF();
  let yPos = 20;
  const lineHeight = 10;
  const margin = 20;
  const pageWidth = doc.internal.pageSize.getWidth();
  const contentWidth = pageWidth - (margin * 2);

  // Helper functions
  const addWrappedText = (text, y) => {
    const lines = doc.splitTextToSize(text, contentWidth);
    doc.text(lines, margin, y);
    return (lines.length * lineHeight);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  // Function to add logo to page
  const addLogoToPage = () => {
    const logoWidth = 40; // Width in mm
    const logoHeight = 15; // Height in mm
    doc.addImage('/logo.png', 'PNG', margin, yPos - 15, logoWidth, logoHeight);
    yPos += logoHeight;
  };

  // Add logo to first page
  addLogoToPage();

  // Title
  doc.setFontSize(20);
  doc.setFont('helvetica', 'bold');
  doc.text('Root Cause Analysis Report', pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight * 2;

  // Project Information
  doc.setFontSize(16);
  doc.text('Project Information', margin, yPos);
  yPos += lineHeight;

  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');
  doc.text(`Project Name: ${projectData.project_name}`, margin, yPos);
  yPos += lineHeight;
  doc.text(`Project ID: ${projectData.project_id}`, margin, yPos);
  yPos += lineHeight;
  doc.text(`Team Members: ${Array.isArray(projectData.team_members) ? projectData.team_members.join(', ') : projectData.team_members}`, margin, yPos);
  yPos += lineHeight;
  doc.text(`Date Created: ${formatDate(projectData.created_at)}`, margin, yPos);
  yPos += lineHeight * 2;

  // Problem Statement
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text('Problem Statement', margin, yPos);
  yPos += lineHeight;

  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');
  yPos += addWrappedText(problemStatement?.full_statement || 'No problem statement available', yPos);
  yPos += lineHeight * 2;

  // Root Cause Identification
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text('Root Cause Identification', margin, yPos);
  yPos += lineHeight * 1.5;

  // Function to add causal factors table
  const addCausalFactorsTable = (title, factors) => {
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text(title, margin, yPos);
    yPos += lineHeight;

    const tableData = [
      ['Root Causes', factors.root.length > 0 ? factors.root.join(', ') : 'No root causes identified'],
      ['Contributing Causes', factors.contributing.length > 0 ? factors.contributing.join(', ') : 'No contributing causes identified'],
      ['Possible Causes', factors.possible.length > 0 ? factors.possible.join(', ') : 'No possible causes identified']
    ];

    doc.autoTable({
      startY: yPos,
      head: [['Type', 'Cause Description']],
      body: tableData,
      margin: { left: margin, right: margin },
      headStyles: { fillColor: [79, 107, 244] },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 'auto' }
      }
    });

    yPos = doc.lastAutoTable.finalY + lineHeight;
  };

  // Add Five Whys table if available
  if (fiveWhysCausalFactors) {
    addCausalFactorsTable('Five Whys Analysis - Causal Factors', fiveWhysCausalFactors);
  }

  // Add Fishbone table if available
  if (fishboneCausalFactors) {
    // Check if we need a new page
    if (yPos > doc.internal.pageSize.getHeight() - 100) {
      doc.addPage();
      yPos = 20;
      addLogoToPage();
    }
    addCausalFactorsTable('Fishbone Diagram - Causal Factors', fishboneCausalFactors);
  }

  // If no causal factors available
  if (!fiveWhysCausalFactors && !fishboneCausalFactors) {
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text('No causal factors analysis available', margin, yPos);
    yPos += lineHeight * 2;
  } else {
    yPos += lineHeight;
  }

  // Check if we need a new page for Corrective Actions
  if (yPos > doc.internal.pageSize.getHeight() - 100) {
    doc.addPage();
    yPos = 20;
    addLogoToPage();
  }

  // Corrective Actions
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text('Corrective Action Plan', margin, yPos);
  yPos += lineHeight;

  if (correctiveActions.length > 0) {
    const tableData = correctiveActions.map(action => [
      action.type,
      action.finding_description,
      action.root_cause,
      action.corrective_action,
      action.responsible_person,
      formatDate(action.target_date)
    ]);

    doc.autoTable({
      startY: yPos,
      head: [['Causal Factor', 'Description', 'Code', 'Actions', 'Responsible', 'Due Date']],
      body: tableData,
      margin: { left: margin, right: margin },
      headStyles: { fillColor: [79, 107, 244] },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 35 },
        2: { cellWidth: 20 },
        3: { cellWidth: 35 },
        4: { cellWidth: 35 },
        5: { cellWidth: 25 }
      }
    });

    yPos = doc.lastAutoTable.finalY + lineHeight;
  } else {
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text('No corrective actions defined', margin, yPos);
    yPos += lineHeight;
  }

  // Review and Approval
  if (reviewData) {
    doc.addPage();
    yPos = 20;

    // Add logo to new page
    addLogoToPage();

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text('Review and Approval', margin, yPos);
    yPos += lineHeight * 1.5;

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(`Prepared By: ${reviewData.prepared_by}`, margin, yPos);
    doc.text(`Date: ${formatDate(reviewData.prepared_date)}`, pageWidth - 60, yPos);
    yPos += lineHeight;
    doc.text(`Approved By: ${reviewData.approved_by}`, margin, yPos);
    doc.text(`Date: ${formatDate(reviewData.approved_date)}`, pageWidth - 60, yPos);
    yPos += lineHeight * 2;

    if (reviewData.comments) {
      doc.setFont('helvetica', 'bold');
      doc.text('Comments:', margin, yPos);
      yPos += lineHeight;
      doc.setFont('helvetica', 'normal');
      yPos += addWrappedText(reviewData.comments, yPos);
    }
  }

  doc.save(`RCA_Report_${projectData.project_id}.pdf`);
};

export default generateReport;
