import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { Card } from './common';
import { theme } from '../styles/theme';

const GuidelinesForWritingCAs = () => {
  const [checklist, setChecklist] = useState({
    accuracy: false,
    measurable: false,
    accountability: false,
    reasonable: false,
    timely: false
  });

  const guidelines = {
    accuracy: [
      "Write in complete sentences",
      "Begin each action with an action verb",
      "Avoid abbreviations and acronyms",
      "Be precise when defining the requirement and deficiency",
      "State what you are going to do",
      "Have one action per corrective action"
    ],
    measurable: [
      "Set actions that may be measurable",
      "Consider how the completion of the action can be documented",
      "Avoid use of terms such as 'all', 'ongoing', 'continue', or 'improve'",
      "Avoid impossible actions to measure",
      "Avoid situations in which the completion of an action is open interpretation"
    ],
    accountability: [
      "Responsibility must be clearly understood and accepted",
      "Resource allocations to support implementation must be clearly established",
      "Ensure necessary qualifications and/or training needs to perform action(s) are identified and understood"
    ],
    reasonable: [
      "Ensure that the corrective action is practical",
      "Discuss the proposed corrective action with those who will implement it"
    ],
    timely: [
      "Avoid establishing target dates which are too optimistic or too far in the future",
      "Evaluate carefully the implementation time frame",
      "Consider funds availability, human resource availability, linked activities and actions, other commitments, and documentation requirements"
    ]
  };

  const handleCheckboxChange = (category) => {
    setChecklist(prev => ({ ...prev, [category]: !prev[category] }));
  };

  return (
    <div className="space-y-6">
      <h2 style={{ color: theme.colors.primary.main }} className="text-2xl font-bold">
        Guidelines for Writing Corrective Actions
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {Object.entries(guidelines).map(([category, items]) => (
          <Card 
            key={category} 
            className={`overflow-hidden transition-all duration-200 hover:shadow-lg
              ${checklist[category] ? `ring-2 ring-[${theme.colors.primary.main}] ring-opacity-50` : ''}`}
          >
            <div className="p-4 bg-gradient-to-br from-gray-50 to-white border-b" 
              style={{ borderColor: theme.colors.border.light }}>
              <label 
                htmlFor={category}
                className="flex items-center space-x-3 cursor-pointer group"
              >
                <div 
                  className={`w-5 h-5 rounded border-2 flex items-center justify-center
                    transition-all duration-200`}
                  style={{ 
                    backgroundColor: checklist[category] ? theme.colors.primary.main : 'transparent',
                    borderColor: checklist[category] ? theme.colors.primary.main : theme.colors.border.main
                  }}
                >
                  {checklist[category] && <Check size={14} className="text-white" />}
                </div>
                <input
                  type="checkbox"
                  id={category}
                  checked={checklist[category]}
                  onChange={() => handleCheckboxChange(category)}
                  className="sr-only"
                />
                <span className="font-semibold text-lg capitalize transition-colors duration-200"
                  style={{ 
                    color: theme.colors.text.primary,
                    ':hover': { color: theme.colors.primary.main }
                  }}>
                  {category}
                </span>
              </label>
            </div>
            
            <ul className="p-4 space-y-3">
              {items.map((item, index) => (
                <li 
                  key={index} 
                  className="flex items-start space-x-3 group"
                  style={{ color: theme.colors.text.secondary }}
                >
                  <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full opacity-75 group-hover:opacity-100 transition-opacity duration-200"
                    style={{ backgroundColor: theme.colors.primary.main }}></div>
                  <span className="text-sm transition-colors duration-200"
                    style={{ 
                      ':hover': { color: theme.colors.text.primary }
                    }}>
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          </Card>
        ))}
      </div>

      <Card className="p-4 mt-6">
        <p className="text-sm italic" style={{ color: theme.colors.text.secondary }}>
          Use these guidelines to ensure your corrective actions are well-defined, measurable, and achievable. 
          Check each category as you review and apply these principles to your corrective actions.
        </p>
      </Card>
    </div>
  );
};

export default GuidelinesForWritingCAs;
