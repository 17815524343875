import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Save, UserCheck, Calendar, MessageSquare } from 'lucide-react';
import { theme } from '../styles/theme';

const ReviewApproval = ({ projectId }) => {
  const [reviewData, setReviewData] = useState({
    preparedBy: '',
    preparedDate: '',
    approvedBy: '',
    approvedDate: '',
    comments: ''
  });
  const [saveStatus, setSaveStatus] = useState('');

  useEffect(() => {
    const fetchReviewData = async () => {
      if (!projectId) return;

      try {
        // First clean up any duplicate entries
        const { data: duplicates } = await supabase
          .from('corrective_actions_review')
          .select('id')
          .eq('project_id', projectId);

        if (duplicates && duplicates.length > 1) {
          // Keep the most recent entry and delete others
          const sortedIds = duplicates
            .map(d => d.id)
            .sort()
            .slice(0, -1);

          await supabase
            .from('corrective_actions_review')
            .delete()
            .in('id', sortedIds);
        }

        // Now fetch the single remaining entry
        const { data, error } = await supabase
          .from('corrective_actions_review')
          .select('*')
          .eq('project_id', projectId)
          .maybeSingle();

        if (error && error.code !== 'PGRST116') {
          throw error;
        }

        if (data) {
          setReviewData({
            preparedBy: data.prepared_by || '',
            preparedDate: data.prepared_date || '',
            approvedBy: data.approved_by || '',
            approvedDate: data.approved_date || '',
            comments: data.comments || ''
          });
        }
      } catch (error) {
        console.error('Error fetching review data:', error);
      }
    };

    fetchReviewData();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReviewData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    if (!projectId) return;

    setSaveStatus('Saving...');
    try {
      // First delete any existing entries
      await supabase
        .from('corrective_actions_review')
        .delete()
        .eq('project_id', projectId);

      // Then insert the new entry
      const { error } = await supabase
        .from('corrective_actions_review')
        .insert({
          project_id: projectId,
          prepared_by: reviewData.preparedBy,
          prepared_date: reviewData.preparedDate || null,
          approved_by: reviewData.approvedBy,
          approved_date: reviewData.approvedDate || null,
          comments: reviewData.comments
        });

      if (error) throw error;
      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving review data:', error);
      setSaveStatus('Error saving');
      setTimeout(() => setSaveStatus(''), 3000);
    }
  };

  return (
    <div className="bg-gradient-to-br from-gray-50 to-white p-6 rounded-xl shadow-lg">
      <h3 className={`text-xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r ${theme.colors.background.gradient.primary}`}>
        Review and Approval
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="group">
          <label className="flex items-center space-x-2 text-sm font-semibold text-indigo-900 uppercase tracking-wide mb-2 transition-colors group-hover:text-indigo-600">
            <UserCheck size={18} className="text-gray-400 group-hover:text-indigo-600 transition-colors" />
            <span>Prepared by:</span>
          </label>
          <input
            type="text"
            name="preparedBy"
            value={reviewData.preparedBy}
            onChange={handleInputChange}
            className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
              focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
              hover:border-indigo-300 transition-all duration-200"
          />
        </div>
        <div className="group">
          <label className="flex items-center space-x-2 text-sm font-semibold text-indigo-900 uppercase tracking-wide mb-2 transition-colors group-hover:text-indigo-600">
            <Calendar size={18} className="text-gray-400 group-hover:text-indigo-600 transition-colors" />
            <span>Date:</span>
          </label>
          <input
            type="date"
            name="preparedDate"
            value={reviewData.preparedDate}
            onChange={handleInputChange}
            className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
              focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
              hover:border-indigo-300 transition-all duration-200"
          />
        </div>
        <div className="group">
          <label className="flex items-center space-x-2 text-sm font-semibold text-indigo-900 uppercase tracking-wide mb-2 transition-colors group-hover:text-indigo-600">
            <UserCheck size={18} className="text-gray-400 group-hover:text-indigo-600 transition-colors" />
            <span>Approved by:</span>
          </label>
          <input
            type="text"
            name="approvedBy"
            value={reviewData.approvedBy}
            onChange={handleInputChange}
            className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
              focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
              hover:border-indigo-300 transition-all duration-200"
          />
        </div>
        <div className="group">
          <label className="flex items-center space-x-2 text-sm font-semibold text-indigo-900 uppercase tracking-wide mb-2 transition-colors group-hover:text-indigo-600">
            <Calendar size={18} className="text-gray-400 group-hover:text-indigo-600 transition-colors" />
            <span>Date:</span>
          </label>
          <input
            type="date"
            name="approvedDate"
            value={reviewData.approvedDate}
            onChange={handleInputChange}
            className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
              focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
              hover:border-indigo-300 transition-all duration-200"
          />
        </div>
      </div>

      <div className="mb-8 group">
        {/* ... [Comments textarea remains exactly the same] ... */}
      </div>

      <div className="flex items-center space-x-4">
        <button
          onClick={handleSave}
          className="px-6 py-3 text-white rounded-lg shadow-md hover:shadow-lg
            transform hover:-translate-y-0.5 transition-all duration-200
            flex items-center space-x-2"
          style={{ 
            backgroundColor: theme.colors.primary.main,
            ':hover': { backgroundColor: theme.colors.primary.hover }
          }}
        >
          <Save size={20} />
          <span>Save Review</span>
        </button>
        
        {saveStatus && (
          <div className={`px-4 py-2 rounded-lg border ${
            saveStatus.includes('Error')
              ? 'bg-red-50 border-red-200 text-red-600'
              : 'bg-emerald-50 border-emerald-200 text-emerald-600'
          }`}>
            {saveStatus}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewApproval;
