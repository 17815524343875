import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import { theme } from '../styles/theme';

const ProjectList = ({ onProjectSelect, onFetchError }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchProjects = useCallback(async (retryCount = 0) => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      let query = supabase
        .from('projects')
        .select('*')
        .order('created_at', { ascending: false });

      // Check if user_id column exists
      const { data: columnInfo, error: columnError } = await supabase
        .from('projects')
        .select('user_id')
        .limit(1);

      if (!columnError && columnInfo.length > 0 && 'user_id' in columnInfo[0]) {
        // If user_id column exists, filter by it
        query = query.eq('user_id', user.id);
      } else {
        console.warn('user_id column not found in projects table. Fetching all projects.');
      }

      const { data, error } = await query;

      if (error) throw error;

      setProjects(data);
      onFetchError(''); // Clear any previous fetch errors
    } catch (error) {
      console.error('Error fetching projects:', error);
      if (retryCount < 3) {
        console.log(`Retrying fetch... Attempt ${retryCount + 1}`);
        setTimeout(() => fetchProjects(retryCount + 1), 1000); // Retry after 1 second
      } else {
        onFetchError('Error loading projects. Please refresh the page.');
      }
    } finally {
      setLoading(false);
    }
  }, [onFetchError]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  if (loading) {
    return <div className="text-center py-4" style={{ color: theme.colors.text.secondary }}>Loading projects...</div>;
  }

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4" style={{ color: theme.colors.primary.main }}>
        Existing Projects
      </h2>
      {projects.length === 0 ? (
        <p style={{ color: theme.colors.text.secondary }}>
          No projects found. Create a new project to get started.
        </p>
      ) : (
        <div className="space-y-4">
          {projects.map(project => (
            <div 
              key={project.id} 
              onClick={() => onProjectSelect(project)} 
              className="cursor-pointer p-4 rounded-md transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
              style={{ 
                borderColor: theme.colors.border.light,
                borderWidth: '1px',
                borderStyle: 'solid',
                backgroundColor: 'white',
                '--tw-shadow-color': theme.colors.primary.light
              }}
            >
              <div className="font-semibold text-lg" style={{ color: theme.colors.text.primary }}>
                {project.project_name}
              </div>
              <div className="text-sm mt-1" style={{ color: theme.colors.text.secondary }}>
                Team: {Array.isArray(project.team_members) ? project.team_members.join(', ') : project.team_members}
              </div>
              <div className="text-xs mt-1" style={{ color: theme.colors.text.secondary }}>
                ID: {project.project_id}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectList;
