import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Save } from 'lucide-react';
import { supabase } from '../supabaseClient';
import { v4 as uuidv4 } from 'uuid';
import { theme } from '../styles/theme';

const CausalFactorTable = ({ projectId, causalFactors }) => {
  const [rows, setRows] = useState([]);
  const [rootCauseCodes, setRootCauseCodes] = useState([]);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');

  // Initialize rows from causal factors and merge with saved actions
  useEffect(() => {
    const initializeRows = async () => {
      if (!projectId || !causalFactors) return;

      try {
        // First, create rows from causal factors
        const combinedFactors = [];

        // Process Five Whys factors
        if (causalFactors.fiveWhys) {
          causalFactors.fiveWhys.forEach(why => {
            if (why.isRoot) {
              combinedFactors.push({
                id: uuidv4(),
                type: 'Root Cause',
                description: why.because,
                classification: '',
                actions: [{
                  id: uuidv4(),
                  action: '',
                  responsible: '',
                  dueDate: ''
                }]
              });
            }
            if (why.contributing) {
              combinedFactors.push({
                id: uuidv4(),
                type: 'Contributing Cause',
                description: why.contributing,
                classification: '',
                actions: [{
                  id: uuidv4(),
                  action: '',
                  responsible: '',
                  dueDate: ''
                }]
              });
            }
          });
        }

        // Process Fishbone factors
        if (causalFactors.fishbone) {
          Object.entries(causalFactors.fishbone).forEach(([type, causes]) => {
            causes.forEach(cause => {
              combinedFactors.push({
                id: uuidv4(),
                type: type === 'root' ? 'Root Cause' :
                      type === 'contributing' ? 'Contributing Cause' :
                      'Possible Cause',
                description: cause,
                classification: '',
                actions: [{
                  id: uuidv4(),
                  action: '',
                  responsible: '',
                  dueDate: ''
                }]
              });
            });
          });
        }

        // Fetch saved actions
        const { data: savedActions, error } = await supabase
          .from('corrective_actions')
          .select('*')
          .eq('project_id', projectId);

        if (error) throw error;

        // Merge saved actions with causal factors
        if (savedActions?.length > 0) {
          combinedFactors.forEach(factor => {
            const matchingActions = savedActions.filter(
              action => action.type === factor.type && action.finding_description === factor.description
            );

            if (matchingActions.length > 0) {
              factor.classification = matchingActions[0].root_cause || '';
              factor.actions = matchingActions.map(action => ({
                id: uuidv4(),
                action: action.corrective_action,
                responsible: action.responsible_person,
                dueDate: action.target_date,
                status: action.status
              }));
            }
          });
        }

        setRows(combinedFactors);
      } catch (err) {
        console.error('Error initializing rows:', err);
        setError('Failed to load data');
      }
    };

    initializeRows();
  }, [projectId, causalFactors]);

  // Fetch root cause codes
  useEffect(() => {
    const fetchRootCauseCodes = async () => {
      if (!projectId) return;

      try {
        const { data, error } = await supabase
          .from('root_causes')
          .select('code, sub_category')
          .eq('project_id', projectId);

        if (error) throw error;
        setRootCauseCodes(data || []);
      } catch (err) {
        console.error('Error fetching root cause codes:', err);
        setError('Failed to load root cause classifications');
      }
    };

    fetchRootCauseCodes();
  }, [projectId]);

  const validateActions = (actions) => {
    const errors = [];
    actions.forEach((action, index) => {
      if (action.action.trim() || action.responsible.trim() || action.dueDate) {
        const missingFields = [];
        if (!action.action.trim()) missingFields.push('description');
        if (!action.responsible.trim()) missingFields.push('responsible person');
        if (!action.dueDate) missingFields.push('due date');
        
        if (missingFields.length > 0) {
          errors.push(`Action ${index + 1} is missing: ${missingFields.join(', ')}`);
        }
      }
    });
    return errors;
  };

  const saveCorrectiveActions = async () => {
    if (!projectId) {
      setError('No project ID provided');
      return;
    }

    try {
      setIsSaving(true);
      setSaveStatus('Saving...');
      setError('');

      // Create actions array from rows
      const validActions = rows.flatMap(row => {
        const filledActions = row.actions.filter(
          action => action.action.trim() || action.responsible.trim() || action.dueDate
        );

        // If no actions are filled, create one empty action to preserve the causal factor
        return filledActions.length > 0 ? filledActions.map(action => ({
          project_id: projectId,
          type: row.type,
          root_cause: row.classification,
          finding_description: row.description,
          corrective_action: action.action.trim(),
          responsible_person: action.responsible.trim(),
          target_date: action.dueDate || null,
          status: action.status || 'Open'
        })) : [{
          project_id: projectId,
          type: row.type,
          root_cause: row.classification,
          finding_description: row.description,
          corrective_action: '',
          responsible_person: '',
          target_date: null,
          status: 'Open'
        }];
      });

      // Validate filled actions
      const filledActions = validActions.filter(
        action => action.corrective_action || action.responsible_person || action.target_date
      );
      const validationErrors = validateActions(
        filledActions.map(action => ({
          action: action.corrective_action,
          responsible: action.responsible_person,
          dueDate: action.target_date
        }))
      );

      if (validationErrors.length > 0) {
        throw new Error(`Please fix the following errors:\n${validationErrors.join('\n')}`);
      }

      // Delete existing actions
      const { error: deleteError } = await supabase
        .from('corrective_actions')
        .delete()
        .eq('project_id', projectId);

      if (deleteError) throw deleteError;

      // Insert all actions
      const { error: insertError } = await supabase
        .from('corrective_actions')
        .insert(validActions);

      if (insertError) throw insertError;

      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (err) {
      console.error('Error saving corrective actions:', err);
      setError(err.message || 'Failed to save corrective actions');
      setSaveStatus('Error saving');
    } finally {
      setIsSaving(false);
    }
  };

  const updateClassification = (rowId, value) => {
    setRows(prevRows => 
      prevRows.map(row =>
        row.id === rowId ? { ...row, classification: value } : row
      )
    );
  };

  const updateAction = (rowId, actionId, field, value) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
              ...row,
              actions: row.actions.map(action =>
                action.id === actionId
                  ? { ...action, [field]: value }
                  : action
              )
            }
          : row
      )
    );
  };

  const addActionToRow = (rowId) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
              ...row,
              actions: [
                ...row.actions,
                {
                  id: uuidv4(),
                  action: '',
                  responsible: '',
                  dueDate: ''
                }
              ]
            }
          : row
      )
    );
  };

  const removeAction = (rowId, actionId) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? {
              ...row,
              actions: row.actions.filter(action => action.id !== actionId)
            }
          : row
      )
    );
  };

  return (
    <div className="bg-gradient-to-br from-gray-50 to-white p-6 rounded-xl shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h3 className={`text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r ${theme.colors.background.gradient.primary}`}>
          Corrective Actions
        </h3>
        <button
          onClick={saveCorrectiveActions}
          disabled={isSaving}
          className="px-6 py-3 text-white rounded-lg shadow-md hover:shadow-lg
            transform hover:-translate-y-0.5 transition-all duration-200
            disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none
            flex items-center space-x-2"
          style={{ backgroundColor: theme.colors.primary.main }}
        >
          <Save size={20} />
          <span>{isSaving ? 'Saving...' : 'Save Actions'}</span>
        </button>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {error}
        </div>
      )}
      
      {saveStatus && (
        <div className={`mb-6 p-4 rounded-lg border ${
          saveStatus.includes('Error') 
            ? 'bg-red-50 border-red-200 text-red-600' 
            : 'bg-emerald-50 border-emerald-200 text-emerald-600'
        }`}>
          {saveStatus}
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="w-full border-collapse bg-white rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-gradient-to-r from-gray-50 to-gray-100">
              <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200 w-[10%]">Type</th>
              <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200 w-[18%]">Cause Description</th>
              <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200 w-[10%]">Classification</th>
              <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200 w-[35%]">Actions</th>
              <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200 w-[15%]">Person Responsible</th>
              <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200 w-[7%]">Due Date</th>
              <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200 w-[5%]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <React.Fragment key={row.id}>
                {row.actions.map((action, actionIndex) => (
                  <tr key={action.id} className="hover:bg-gray-50 transition-colors">
                    {actionIndex === 0 && (
                      <>
                        <td className="p-4 border-b border-gray-100" rowSpan={row.actions.length}>
                          <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                            row.type === 'Root Cause' 
                              ? 'bg-blue-100 text-blue-800'
                              : row.type === 'Contributing Cause'
                              ? 'bg-purple-100 text-purple-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                            {row.type}
                          </span>
                        </td>
                        <td className="p-4 border-b border-gray-100" rowSpan={row.actions.length}>
                          {row.description}
                        </td>
                        <td className="p-4 border-b border-gray-100" rowSpan={row.actions.length}>
                          <select
                            value={row.classification}
                            onChange={(e) => updateClassification(row.id, e.target.value)}
                            className="w-full px-2 py-2 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
                              focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
                              hover:border-indigo-300 transition-all duration-200 text-sm"
                          >
                            <option value="">Select</option>
                            {rootCauseCodes.map(code => (
                              <option key={code.code} value={code.code}>
                                {code.code} - {code.sub_category}
                              </option>
                            ))}
                          </select>
                        </td>
                      </>
                    )}
                    <td className="p-4 border-b border-gray-100">
                      <textarea
                        value={action.action}
                        onChange={(e) => updateAction(row.id, action.id, 'action', e.target.value)}
                        className="w-full px-4 py-2 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
                          focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
                          hover:border-indigo-300 transition-all duration-200 resize-y"
                        placeholder="Enter corrective action (1-2 sentences)"
                        rows={2}
                        style={{ 
                          minHeight: '65px',
                          maxHeight: '150px',
                          lineHeight: '1.5',
                          fontSize: '0.875rem',
                          overflowY: 'auto',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word'
                        }}
                      />
                    </td>
                    <td className="p-4 border-b border-gray-100">
                      <input
                        type="text"
                        value={action.responsible}
                        onChange={(e) => updateAction(row.id, action.id, 'responsible', e.target.value)}
                        className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
                          focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
                          hover:border-indigo-300 transition-all duration-200"
                        placeholder="Enter name"
                      />
                    </td>
                    <td className="p-4 border-b border-gray-100">
                      <input
                        type="date"
                        value={action.dueDate}
                        onChange={(e) => updateAction(row.id, action.id, 'dueDate', e.target.value)}
                        className="w-full px-2 py-2 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
                          focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
                          hover:border-indigo-300 transition-all duration-200 text-sm"
                      />
                    </td>
                    <td className="p-4 border-b border-gray-100">
                      <div className="flex space-x-2">
                        {row.actions.length > 1 && (
                          <button
                            onClick={() => removeAction(row.id, action.id)}
                            className="p-2 text-red-500 hover:text-red-700 hover:bg-red-50 rounded-lg
                              transition-all duration-200"
                            title="Remove action"
                          >
                            <Trash2 size={20} />
                          </button>
                        )}
                        {actionIndex === row.actions.length - 1 && (
                          <button
                            onClick={() => addActionToRow(row.id)}
                            className="p-2 text-[#4F6BF4] hover:text-[#4059DB] hover:bg-indigo-50 rounded-lg
                              transition-all duration-200"
                            title="Add action"
                          >
                            <Plus size={20} />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CausalFactorTable;
