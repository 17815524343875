import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Download, FileText, Users, Calendar, ClipboardList, UserCheck } from 'lucide-react';
import generateReport from './ReportDownloader';
import { theme } from '../styles/theme';

const Section = ({ title, icon: Icon, children }) => (
  <section className="bg-white p-6 rounded-xl shadow-md border border-gray-100 mb-6 hover:shadow-lg transition-shadow duration-200">
    <h3 className="text-xl font-bold mb-6 flex items-center space-x-3">
      <div className={`p-2 rounded-lg text-white shadow-sm bg-gradient-to-br ${theme.colors.background.gradient.primary}`}>
        <Icon size={20} />
      </div>
      <span className={`text-transparent bg-clip-text bg-gradient-to-r ${theme.colors.background.gradient.primary}`}>
        {title}
      </span>
    </h3>
    {children}
  </section>
);

const CausalFactorsTable = ({ title, factors }) => (
  <div className="mb-6">
    <h4 className="text-lg font-semibold mb-4" style={{ color: theme.colors.primary.main }}>
      {title}
    </h4>
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-50">
            <th className="w-1/4 p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Type</th>
            <th className="w-3/4 p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Cause Description</th>
          </tr>
        </thead>
        <tbody>
          <tr className="hover:bg-gray-50 transition-colors">
            <td className="w-1/4 p-4 font-semibold border-b border-gray-100" style={{ color: theme.colors.primary.main }}>
              Root Causes
            </td>
            <td className="w-3/4 p-4 border-b border-gray-100">
              {factors.root.length > 0 ? 
                factors.root.join(', ') : 
                <span className="text-gray-500 italic">No root causes identified</span>
              }
            </td>
          </tr>
          <tr className="hover:bg-gray-50 transition-colors">
            <td className="w-1/4 p-4 font-semibold border-b border-gray-100" style={{ color: theme.colors.primary.main }}>
              Contributing Causes
            </td>
            <td className="w-3/4 p-4 border-b border-gray-100">
              {factors.contributing.length > 0 ? 
                factors.contributing.join(', ') : 
                <span className="text-gray-500 italic">No contributing causes identified</span>
              }
            </td>
          </tr>
          <tr className="hover:bg-gray-50 transition-colors">
            <td className="w-1/4 p-4 font-semibold" style={{ color: theme.colors.primary.main }}>
              Possible Causes
            </td>
            <td className="w-3/4 p-4">
              {factors.possible.length > 0 ? 
                factors.possible.join(', ') : 
                <span className="text-gray-500 italic">No possible causes identified</span>
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

const ReportGenerator = ({ projectId }) => {
  const [projectData, setProjectData] = useState(null);
  const [problemStatement, setProblemStatement] = useState(null);
  const [correctiveActions, setCorrectiveActions] = useState([]);
  const [reviewData, setReviewData] = useState(null);
  const [fiveWhysCausalFactors, setFiveWhysCausalFactors] = useState(null);
  const [fishboneCausalFactors, setFishboneCausalFactors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReportData = async () => {
      if (!projectId) return;

      try {
        setLoading(true);
        setError(null);

        // Fetch project details
        const { data: project } = await supabase
          .from('projects')
          .select('*')
          .eq('id', projectId)
          .single();

        setProjectData(project);

        // Fetch problem statement
        const { data: problem } = await supabase
          .from('problem_statements')
          .select('*')
          .eq('project_id', projectId)
          .single();

        setProblemStatement(problem);

        // Fetch Five Whys data
        const { data: whysData } = await supabase
          .from('five_whys_analysis')
          .select('whys')
          .eq('project_id', projectId)
          .maybeSingle();
        
        if (whysData?.whys) {
          const factors = {
            root: [],
            contributing: [],
            possible: []
          };

          whysData.whys.forEach(why => {
            if (why.isRoot === 'Yes' || why.isRoot === true) {
              if (why.contributing) {
                const contributingCauses = why.contributing.split(',')
                  .map(cause => cause.trim())
                  .filter(cause => cause.length > 0);
                factors.contributing.push(...contributingCauses);
              }
              if (why.possible) {
                const possibleCauses = why.possible.split(',')
                  .map(cause => cause.trim())
                  .filter(cause => cause.length > 0);
                factors.possible.push(...possibleCauses);
              }
              if (why.because) {
                factors.root.push(why.because.trim());
              }
            }
          });

          if (factors.root.length > 0 || factors.contributing.length > 0 || factors.possible.length > 0) {
            setFiveWhysCausalFactors(factors);
          }
        }

        // Fetch Fishbone data
        const { data: fishboneData } = await supabase
          .from('fishbone_diagrams')
          .select('data')
          .eq('project_id', projectId)
          .maybeSingle();
        
        if (fishboneData?.data?.cause_classifications) {
          const classifications = fishboneData.data.cause_classifications;
          if (classifications.root.length > 0 || 
              classifications.contributing.length > 0 || 
              classifications.possible.length > 0) {
            setFishboneCausalFactors(classifications);
          }
        }

        // Fetch corrective actions
        const { data: actions } = await supabase
          .from('corrective_actions')
          .select('*')
          .eq('project_id', projectId);

        setCorrectiveActions(actions || []);

        // Fetch review data
        const { data: review } = await supabase
          .from('corrective_actions_review')
          .select('*')
          .eq('project_id', projectId)
          .single();

        setReviewData(review);
      } catch (err) {
        console.error('Error fetching report data:', err);
        setError('Failed to load report data');
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [projectId]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleDownload = () => {
    generateReport({
      projectData,
      problemStatement,
      fiveWhysCausalFactors,
      fishboneCausalFactors,
      correctiveActions,
      reviewData
    });
  };

  if (loading) {
    return <div className="text-center py-4 text-gray-600">Loading report...</div>;
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
        {error}
      </div>
    );
  }

  if (!projectData) {
    return (
      <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg text-yellow-700">
        No project data available
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-gray-50 to-white p-6 rounded-xl shadow-lg">
      <div className="flex justify-between items-center mb-8">
        <h2 className={`text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r ${theme.colors.background.gradient.primary}`}>
          Root Cause Analysis Report
        </h2>
        <button
          className={`px-6 py-3 text-white rounded-lg shadow-md hover:shadow-lg
            transform hover:-translate-y-0.5 transition-all duration-200
            flex items-center space-x-2 bg-gradient-to-r ${theme.colors.background.gradient.primary}`}
          onClick={handleDownload}
        >
          <Download size={20} />
          <span>Download Report</span>
        </button>
      </div>

      {/* Project Information */}
      <Section title="Project Information" icon={FileText}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <p className="text-sm font-semibold text-indigo-900 uppercase tracking-wide">Project Name:</p>
            <p className="text-gray-700">{projectData.project_name}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-semibold text-indigo-900 uppercase tracking-wide">Project ID:</p>
            <p className="text-gray-700">{projectData.project_id}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-semibold text-indigo-900 uppercase tracking-wide">Team Members:</p>
            <p className="text-gray-700">
              {Array.isArray(projectData.team_members) ? projectData.team_members.join(', ') : projectData.team_members}
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-semibold text-indigo-900 uppercase tracking-wide">Date Created:</p>
            <p className="text-gray-700">{formatDate(projectData.created_at)}</p>
          </div>
        </div>
      </Section>

      {/* Problem Statement */}
      <Section title="Problem Statement" icon={ClipboardList}>
        <p className="text-gray-700 leading-relaxed">
          {problemStatement?.full_statement || 'No problem statement available'}
        </p>
      </Section>

      {/* Root Cause Identification */}
      <Section title="Root Cause Identification" icon={ClipboardList}>
        {fiveWhysCausalFactors && (
          <CausalFactorsTable 
            title="Five Whys Analysis - Causal Factors" 
            factors={fiveWhysCausalFactors} 
          />
        )}
        {fishboneCausalFactors && (
          <CausalFactorsTable 
            title="Fishbone Diagram - Causal Factors" 
            factors={fishboneCausalFactors} 
          />
        )}
        {!fiveWhysCausalFactors && !fishboneCausalFactors && (
          <p className="text-gray-500 italic">No causal factors analysis available</p>
        )}
      </Section>

      {/* Corrective Actions */}
      <Section title="Corrective Action Plan" icon={Calendar}>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse bg-white rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gradient-to-r from-gray-50 to-gray-100">
                <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Causal Factor</th>
                <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Cause Description</th>
                <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Root Cause Code</th>
                <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Corrective Actions</th>
                <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Person Responsible</th>
                <th className="p-4 text-left font-semibold text-gray-700 border-b-2 border-gray-200">Due Date</th>
              </tr>
            </thead>
            <tbody>
              {correctiveActions.length > 0 ? (
                correctiveActions.map((action, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition-colors">
                    <td className="p-4 border-b border-gray-100">
                      <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                        action.type === 'Root Cause' 
                          ? 'bg-blue-100 text-blue-800'
                          : action.type === 'Contributing Cause'
                          ? 'bg-purple-100 text-purple-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {action.type}
                      </span>
                    </td>
                    <td className="p-4 border-b border-gray-100">{action.finding_description}</td>
                    <td className="p-4 border-b border-gray-100">{action.root_cause}</td>
                    <td className="p-4 border-b border-gray-100">{action.corrective_action}</td>
                    <td className="p-4 border-b border-gray-100">{action.responsible_person}</td>
                    <td className="p-4 border-b border-gray-100">{formatDate(action.target_date)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="p-4 text-center text-gray-500">
                    No corrective actions defined
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Section>

      {/* Review and Approval */}
      {reviewData && (
        <Section title="Review and Approval" icon={UserCheck}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <p className="text-sm font-semibold text-indigo-900 uppercase tracking-wide">Prepared By:</p>
              <p className="text-gray-700">{reviewData.prepared_by}</p>
              <p className="text-sm text-gray-500">{formatDate(reviewData.prepared_date)}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm font-semibold text-indigo-900 uppercase tracking-wide">Approved By:</p>
              <p className="text-gray-700">{reviewData.approved_by}</p>
              <p className="text-sm text-gray-500">{formatDate(reviewData.approved_date)}</p>
            </div>
            {reviewData.comments && (
              <div className="col-span-2 space-y-2">
                <p className="text-sm font-semibold text-indigo-900 uppercase tracking-wide">Comments:</p>
                <p className="text-gray-700 leading-relaxed">{reviewData.comments}</p>
              </div>
            )}
          </div>
        </Section>
      )}
    </div>
  );
};

export default ReportGenerator;
