import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { theme } from '../styles/theme';

const ProjectInitialization = ({ onProjectInit }) => {
  const [projectName, setProjectName] = useState('');
  const [teamMembers, setTeamMembers] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const newProject = {
        project_name: projectName,
        team_members: teamMembers.split(',').map(member => member.trim()),
        user_id: user.id
      };

      const { data, error } = await supabase
        .from('projects')
        .insert([newProject])
        .select()
        .single();

      if (error) throw error;
      console.log('Project created successfully:', data);
      onProjectInit(data);
    } catch (error) {
      console.error('Detailed error:', error);
      setError('Error creating project: ' + error.message);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6" style={{ color: theme.colors.primary.main }}>
        Initialize Root Cause Analysis Project
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="projectName" className="block text-sm font-medium mb-1" style={{ color: theme.colors.text.primary }}>
            Project Name:
          </label>
          <input
            type="text"
            id="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2"
            style={{ 
              borderColor: theme.colors.border.light,
              '--tw-ring-color': theme.colors.primary.main,
              '--tw-ring-opacity': 1
            }}
          />
        </div>
        <div>
          <label htmlFor="teamMembers" className="block text-sm font-medium mb-1" style={{ color: theme.colors.text.primary }}>
            Team Members (comma-separated):
          </label>
          <input
            type="text"
            id="teamMembers"
            value={teamMembers}
            onChange={(e) => setTeamMembers(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2"
            style={{ 
              borderColor: theme.colors.border.light,
              '--tw-ring-color': theme.colors.primary.main,
              '--tw-ring-opacity': 1
            }}
          />
        </div>
        <button 
          type="submit" 
          className="w-full py-2 px-4 rounded-md text-white transition duration-300 ease-in-out"
          style={{ 
            backgroundColor: theme.colors.primary.main,
            ':hover': { backgroundColor: theme.colors.primary.hover }
          }}
        >
          Initialize Project
        </button>
      </form>
      {error && <p style={{ color: theme.colors.error.main }} className="mt-4">{error}</p>}
    </div>
  );
};

export default ProjectInitialization;
