import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import ProblemStatementBuilder from './ProblemStatementBuilder';
import { theme } from '../styles/theme';

const ProblemDefinition = ({ projectId }) => {
  const [problemStatement, setProblemStatement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchProblemStatement = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('problem_statements')
        .select('*')
        .eq('project_id', projectId)
        .limit(1);

      if (error) throw error;

      if (data && data.length > 0) {
        setProblemStatement(data[0]);
      } else {
        setProblemStatement({});
      }
      setError('');
    } catch (error) {
      console.error('Error fetching problem statement:', error);
      setError('Failed to load problem statement. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchProblemStatement();
  }, [fetchProblemStatement]);

  if (loading) {
    return <div className={`text-center py-4 text-[${theme.colors.text.secondary}]`}>Loading problem definition...</div>;
  }

  return (
    <div>
      <h2 className={`text-2xl font-bold text-[${theme.colors.primary.main}] mb-6`}>Problem Definition</h2>
      {error && <p className={`text-[${theme.colors.error.main}] mb-4`}>{error}</p>}
      <ProblemStatementBuilder
        initialStatement={problemStatement}
        projectId={projectId}
      />
    </div>
  );
};

export default ProblemDefinition;
