import React from 'react';
import { commonStyles } from '../../styles/theme';

const Input = ({ 
  label,
  error,
  className = '', 
  ...props 
}) => {
  return (
    <div className="space-y-1">
      {label && (
        <label className="block text-sm font-semibold text-[#103558]">
          {label}
        </label>
      )}
      <input
        className={`${commonStyles.input.base} ${
          error ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : ''
        } ${className}`}
        {...props}
      />
      {error && (
        <p className="text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};

export default Input;
