import React from 'react';
import { commonStyles } from '../../styles/theme';

const Button = ({ 
  children, 
  variant = 'primary', 
  className = '', 
  icon: Icon,
  ...props 
}) => {
  const getVariantStyles = () => {
    switch (variant) {
      case 'secondary':
        return commonStyles.button.secondary;
      case 'accent':
        return commonStyles.button.accent;
      default:
        return commonStyles.button.primary;
    }
  };

  return (
    <button
      className={`${commonStyles.button.base} ${getVariantStyles()} ${className}`}
      {...props}
    >
      <div className="flex items-center space-x-2">
        {Icon && <Icon size={20} />}
        <span>{children}</span>
      </div>
    </button>
  );
};

export default Button;
