import React, { useState } from 'react';

const Glossary = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSection, setActiveSection] = useState('terms');

  if (!isOpen) return null;

  const terms = [
    {
      term: "Problem",
      definition: "A situation or condition that deviates from the expected norm, requiring investigation and resolution to improve safety or performance."
    },
    {
      term: "Incident",
      definition: "An occurrence that could have resulted in or did result in an accident, injury, or loss, requiring investigation to prevent recurrence."
    },
    {
      term: "Data Collection",
      definition: "The systematic gathering of information, evidence, and facts related to an incident to understand what happened, why, and how it can be prevented in the future."
    },
    {
      term: "Examination",
      definition: "A detailed and thorough inspection of evidence, processes, or systems to deduce patterns or faults that led to an incident."
    },
    {
      term: "Causal Factor",
      definition: "A specific condition or event that directly contributes to an incident or failure."
    },
    {
      term: "Contributing Cause",
      definition: "An underlying factor that, while not the primary root cause, plays a significant role in the occurrence of an incident or failure."
    },
    {
      term: "Possible Cause",
      definition: "A hypothesis or potential explanation for why an incident occurred, which requires further investigation to confirm or eliminate."
    },
    {
      term: "Analysis",
      definition: "The process of examining collected data to identify patterns, relationships, and root causes using various structured tools and techniques."
    },
    {
      term: "5 Whys",
      definition: "A problem-solving technique that involves asking 'why' multiple times (usually five) to drill down to the root cause of a problem."
    },
    {
      term: "Fishbone Diagram",
      definition: "A visual tool used to systematically identify and categorize potential causes of a problem, showing relationships between them."
    },
    {
      term: "Fault Tree Analysis",
      definition: "A top-down approach that uses a graphical representation to analyze the pathways that can lead to a safety incident or failure."
    },
    {
      term: "Failure Mode and Effects Analysis (FMEA)",
      definition: "A systematic methodology for evaluating processes to identify where and how they might fail, and assessing the relative impact of different failures."
    },
    {
      term: "Human Factors",
      definition: "Aspects of human behavior, capabilities, and limitations that can influence the performance of individuals and teams in the safety management system."
    },
    {
      term: "Non-Conformance",
      definition: "Failure to meet a requirement or standard, indicating a potential safety issue that may arise."
    },
    {
      term: "Corrective Action",
      definition: "Steps taken to eliminate the root cause of an identified problem or incident to prevent recurrence."
    },
    {
      term: "Preventative Action",
      definition: "Measures implemented to reduce the likelihood of a potential incident or failure before it occurs."
    },
    {
      term: "Containment Action",
      definition: "Immediate measures implemented to limit the impact of an incident and prevent further escalation."
    },
    {
      term: "Action Plan",
      definition: "A detailed strategy outlining how corrective and preventative actions will be implemented, scheduled, and monitored for effectiveness."
    },
    {
      term: "Verification",
      definition: "The process of confirming that corrective or preventative actions have been correctly implemented."
    },
    {
      term: "Validation",
      definition: "The evaluation of the effectiveness of implemented actions to ensure they adequately address the identified root causes."
    },
    {
      term: "Documentation",
      definition: "The process of recording findings, evidence, analyses, and actions taken during the RCA process, ensuring transparency, accountability, and compliance."
    },
    {
      term: "Safety Management System (SMS)",
      definition: "A systematic approach to managing safety, including organizational structures, policies, responsibilities, and procedures to improve overall safety performance."
    }
  ];

  const acronyms = [
    {
      acronym: "CA",
      fullForm: "Corrective action",
      definition: "Steps taken to eliminate the root cause of an identified problem or incident to prevent recurrence."
    },
    {
      acronym: "CAA",
      fullForm: "Civil Aviation Agency – Various countries",
      definition: "National authorities responsible for regulating civil aviation safety and compliance in their respective countries."
    },
    {
      acronym: "CAR",
      fullForm: "Corrective action record",
      definition: "A formal document that contains records of corrective actions taken to address identified issues."
    },
    {
      acronym: "CAP",
      fullForm: "Corrective action plan",
      definition: "A detailed outline of the steps to be taken to address identified issues and their root causes."
    },
    {
      acronym: "EASA",
      fullForm: "European Aviation Safety Agency",
      definition: "The agency responsible for promoting and ensuring the highest safety standards in civil aviation across Europe."
    },
    {
      acronym: "IAR",
      fullForm: "IOSA audit report",
      definition: "A report that summarizes findings from the IOSA audit, assessing compliance with safety and operational standards."
    },
    {
      acronym: "ICAO",
      fullForm: "International Civil Aviation Organization treaty of 1947",
      definition: "A specialized agency of the United Nations that sets international standards for aviation safety and security."
    },
    {
      acronym: "ISM",
      fullForm: "IOSA Standards Manual",
      definition: "A document outlining the standards and recommended practices for the IOSA audit process."
    },
    {
      acronym: "ISARP",
      fullForm: "IOSA Standards and Recommended Practices",
      definition: "The set of guidelines that organizations must follow to maintain compliance with IOSA standards."
    },
    {
      acronym: "MORT",
      fullForm: "Management oversight review technique",
      definition: "A structured method for evaluating the effectiveness of management oversight in safety and operational processes."
    },
    {
      acronym: "ORO",
      fullForm: "Organization Requirements for Air Operations (Part ORO of EASA regulations)",
      definition: "Regulatory requirements that outline the necessary organizational structures and processes for safe air operations."
    },
    {
      acronym: "PDCA (PDSA)",
      fullForm: "Plan, do, check or study, act",
      definition: "A cyclical management process used for continuous improvement in processes and products."
    },
    {
      acronym: "RCA",
      fullForm: "Root cause analysis",
      definition: "A systematic approach for identifying the underlying causes of problems to prevent recurrence."
    },
    {
      acronym: "SMS",
      fullForm: "Safety Management System",
      definition: "A systematic approach to managing safety that includes organizational structures, policies, and procedures."
    },
  ];

  const filteredTerms = terms.filter(item =>
    item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.definition.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredAcronyms = acronyms.filter(item =>
    item.acronym.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.fullForm.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.definition.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto p-6">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-blue-600">Aviation RCA Glossary</h1>
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded flex items-center space-x-2 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            <span>Back to Editor</span>
          </button>
        </div>

        <div className="mb-6">
          <input
            type="text"
            placeholder="Search terms and acronyms..."
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="flex mb-6 border-b">
          <button
            className={`px-4 py-2 ${activeSection === 'terms' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-600'}`}
            onClick={() => setActiveSection('terms')}
          >
            Terms
          </button>
          <button
            className={`px-4 py-2 ${activeSection === 'acronyms' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-600'}`}
            onClick={() => setActiveSection('acronyms')}
          >
            Acronyms
          </button>
        </div>

        <div className="space-y-4">
          {activeSection === 'terms' ? (
            filteredTerms.map((item, index) => (
              <div key={index} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">{item.term}</h3>
                <p className="text-gray-600">{item.definition}</p>
              </div>
            ))
          ) : (
            filteredAcronyms.map((item, index) => (
              <div key={index} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {item.acronym} - {item.fullForm}
                </h3>
                <p className="text-gray-600">{item.definition}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Glossary;
