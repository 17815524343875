import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { PlusCircle, Trash2 } from 'lucide-react';
import { theme } from '../styles/theme';

// Register the annotation plugin
Chart.register(annotationPlugin);

const ChartGeneratorTool = () => {
  const [chartType, setChartType] = useState('Pareto Chart');
  const [chartTitle, setChartTitle] = useState('');
  const [data, setData] = useState([{ label: '', value: '' }]);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const handleAddRow = () => {
    setData([...data, { label: '', value: '' }]);
  };

  const handleRemoveRow = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
  };

  const handleDataChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = field === 'value' ? (value === '' ? '' : Number(value)) : value;
    setData(newData);
  };

  const generateParetoData = (inputData) => {
    // Filter out incomplete entries
    const validData = inputData.filter(item => item.label && item.value !== '');
    
    // Sort data by value in descending order
    const sortedData = [...validData].sort((a, b) => b.value - a.value);
    
    // Calculate cumulative percentage
    const total = sortedData.reduce((sum, item) => sum + item.value, 0);
    let cumulative = 0;
    const paretoLine = sortedData.map(item => {
      cumulative += item.value;
      return (cumulative / total) * 100;
    });

    return {
      labels: sortedData.map(item => item.label),
      values: sortedData.map(item => item.value),
      cumulative: paretoLine
    };
  };

  const generateHistogramData = (inputData) => {
    // Filter out incomplete entries
    const validData = inputData.filter(item => item.label && item.value !== '');
    
    // Create frequency map for each label
    const frequencyMap = new Map();
    validData.forEach(item => {
      const count = frequencyMap.get(item.label) || 0;
      frequencyMap.set(item.label, count + item.value);
    });

    // Sort by frequency in descending order
    const sortedEntries = Array.from(frequencyMap.entries())
      .sort((a, b) => b[1] - a[1]);

    return {
      labels: sortedEntries.map(([label]) => label),
      values: sortedEntries.map(([_, value]) => value)
    };
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext('2d');
    let chartData;
    let config;

    // Only create chart if we have valid data
    const validData = data.filter(item => item.label && item.value !== '');
    if (validData.length === 0) return;

    if (chartType === 'Pareto Chart') {
      const paretoData = generateParetoData(data);
      config = {
        type: 'bar',
        data: {
          labels: paretoData.labels,
          datasets: [
            {
              type: 'bar',
              label: 'Value',
              data: paretoData.values,
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              order: 1
            },
            {
              type: 'line',
              label: 'Cumulative %',
              data: paretoData.cumulative,
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 2,
              fill: false,
              yAxisID: 'percentage',
              order: 0,
              pointRadius: 4,
              pointBackgroundColor: 'rgba(255, 99, 132, 1)'
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Value'
              },
              grid: {
                display: false
              }
            },
            percentage: {
              position: 'right',
              beginAtZero: true,
              max: 100,
              title: {
                display: true,
                text: 'Cumulative %'
              },
              grid: {
                display: false
              }
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: chartTitle || 'Pareto Chart'
            },
            annotation: {
              annotations: {
                eightyPercentLine: {
                  type: 'line',
                  yMin: 80,
                  yMax: 80,
                  yScaleID: 'percentage',
                  borderColor: 'rgba(0, 0, 0, 0.5)',
                  borderWidth: 1,
                  borderDash: [5, 5],
                  drawTime: 'beforeDatasetsDraw'
                }
              }
            }
          }
        }
      };
    } else { // Histogram
      const histogramData = generateHistogramData(data);
      config = {
        type: 'bar',
        data: {
          labels: histogramData.labels,
          datasets: [{
            label: 'Frequency',
            data: histogramData.values,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Frequency'
              },
              grid: {
                display: false
              }
            },
            x: {
              title: {
                display: true,
                text: 'Components/Issues'
              },
              grid: {
                display: false
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: chartTitle || 'Histogram'
            },
            legend: {
              display: false
            }
          }
        }
      };
    }

    chartInstance.current = new Chart(ctx, config);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, chartType, chartTitle]);

  return (
    <div className="bg-gradient-to-br from-gray-50 to-white p-6 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold mb-8" style={{ color: theme.colors.primary.main }}>
        Chart Generator Tool
      </h2>
      
      <div className="space-y-6">
        <div className="group">
          <label className="block text-sm font-semibold uppercase tracking-wide mb-2" 
            style={{ color: theme.colors.text.primary }}>
            Chart Type:
          </label>
          <select
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            className="w-full px-4 py-3 rounded-lg border-2 bg-white shadow-sm 
              focus:ring-2 transition-all duration-200"
            style={{ 
              borderColor: theme.colors.border.light,
              '--tw-ring-color': theme.colors.primary.main,
              '--tw-ring-opacity': 1
            }}
          >
            <option value="Pareto Chart">Pareto Chart</option>
            <option value="Histogram">Histogram</option>
          </select>
        </div>

        <div className="group">
          <label className="block text-sm font-semibold uppercase tracking-wide mb-2" 
            style={{ color: theme.colors.text.primary }}>
            Chart Title:
          </label>
          <input
            type="text"
            value={chartTitle}
            onChange={(e) => setChartTitle(e.target.value)}
            placeholder="Enter chart title"
            className="w-full px-4 py-3 rounded-lg border-2 bg-white shadow-sm 
              focus:ring-2 transition-all duration-200"
            style={{ 
              borderColor: theme.colors.border.light,
              '--tw-ring-color': theme.colors.primary.main,
              '--tw-ring-opacity': 1
            }}
          />
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md" 
          style={{ borderColor: theme.colors.border.light }}>
          <h3 className="text-xl font-semibold mb-6" style={{ color: theme.colors.text.primary }}>
            Enter Data
          </h3>
          <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-5 text-sm font-semibold uppercase tracking-wide" 
              style={{ color: theme.colors.text.primary }}>Label</div>
            <div className="col-span-5 text-sm font-semibold uppercase tracking-wide" 
              style={{ color: theme.colors.text.primary }}>Value</div>
            <div className="col-span-2 text-sm font-semibold uppercase tracking-wide" 
              style={{ color: theme.colors.text.primary }}>Action</div>
          </div>
          
          <div className="space-y-3">
            {data.map((row, index) => (
              <div key={index} className="grid grid-cols-12 gap-4 items-center group">
                <input
                  type="text"
                  value={row.label}
                  onChange={(e) => handleDataChange(index, 'label', e.target.value)}
                  placeholder="Enter label"
                  className="col-span-5 px-4 py-2 rounded-lg border-2 bg-white shadow-sm 
                    focus:ring-2 transition-all duration-200"
                  style={{ 
                    borderColor: theme.colors.border.light,
                    '--tw-ring-color': theme.colors.primary.main,
                    '--tw-ring-opacity': 1
                  }}
                />
                <input
                  type="number"
                  value={row.value}
                  onChange={(e) => handleDataChange(index, 'value', e.target.value)}
                  placeholder="Enter value"
                  className="col-span-5 px-4 py-2 rounded-lg border-2 bg-white shadow-sm 
                    focus:ring-2 transition-all duration-200"
                  style={{ 
                    borderColor: theme.colors.border.light,
                    '--tw-ring-color': theme.colors.primary.main,
                    '--tw-ring-opacity': 1
                  }}
                />
                <button
                  onClick={() => handleRemoveRow(index)}
                  className="col-span-2 p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  style={{ color: theme.colors.accent.main }}
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>

          <button
            onClick={handleAddRow}
            className="mt-6 px-6 py-3 text-white rounded-lg shadow-md hover:shadow-lg
              transform hover:-translate-y-0.5 transition-all duration-200
              flex items-center space-x-2"
            style={{ 
              backgroundColor: theme.colors.primary.main,
              ':hover': {
                backgroundColor: theme.colors.primary.hover
              }
            }}
          >
            <PlusCircle className="h-5 w-5" />
            <span>Add Row</span>
          </button>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-md" 
          style={{ height: '500px', borderColor: theme.colors.border.light }}>
          <canvas ref={chartRef}></canvas>
        </div>
      </div>
    </div>
  );
};

export default ChartGeneratorTool;
