import React, { useState, useEffect, useRef } from 'react';
import { X, Send, Loader } from 'lucide-react';
import { useAssistant } from '../contexts/AssistantContext';

const AIAssistant = ({ activeTab, pageContext, project }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const {
    messages,
    isLoading,
    error,
    sendMessage,
    clearThread,
    initializeThread,
    updateContext
  } = useAssistant();

  useEffect(() => {
    const contextData = {
      activeTab,
      pageContext,
      project: {
        id: project.id,
        name: project.project_name,
        team: project.team_members
      }
    };
    updateContext(contextData);
  }, [activeTab, pageContext, project, updateContext]);

  useEffect(() => {
    const initThread = async () => {
      try {
        if (!messages.length) {
          await initializeThread();
        }
      } catch (err) {
        console.error('Failed to initialize thread:', err);
      }
    };
    initThread();
  }, [initializeThread, messages.length]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    try {
      await sendMessage(input);
      setInput('');
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  const handleClear = async () => {
    try {
      await clearThread();
    } catch (err) {
      console.error('Error clearing thread:', err);
    }
  };

  const toggleAssistant = () => {
    setIsOpen(!isOpen);
  };

  const extractUserMessage = (content) => {
    if (!content) return '';
    
    if (typeof content === 'string') {
      const match = content.match(/User Message: (.+)$/);
      return match ? match[1] : content;
    }

    if (Array.isArray(content)) {
      const textContent = content.find(item => item.type === 'text' || item.text?.value);
      const text = textContent?.text?.value || textContent?.value || '';
      const match = text.match(/User Message: (.+)$/);
      return match ? match[1] : text;
    }

    return 'No message content available';
  };

  const renderMessageContent = (message) => {
    if (!message?.content) {
      return 'No message content available';
    }

    if (message.role === 'user') {
      return extractUserMessage(message.content);
    }

    if (typeof message.content === 'string') {
      return message.content;
    }

    const textContent = message.content.find(item => 
      item.type === 'text' || item.text?.value
    );

    return textContent?.text?.value || textContent?.value || 'No text content available';
  };

  const reversedMessages = [...messages].reverse();

  return (
    <>
      <div className="fixed bottom-4 right-4 group">
        <button
          onClick={toggleAssistant}
          className="p-1 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-200 w-16 h-16 flex items-center justify-center overflow-hidden relative"
          aria-label={isOpen ? "Close AI Assistant" : "Open AI Assistant"}
        >
          {isOpen ? (
            <X size={28} />
          ) : (
            <img 
              src="/MaRCiA2.png" 
              alt="MaRCia, AI Assistant" 
              className="w-full h-full object-cover rounded-full"
            />
          )}
        </button>
        <div className="absolute bottom-full right-0 mb-2 px-3 py-1 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
          MaRCia, your AI assistant
        </div>
      </div>

      {isOpen && (
        <div className="fixed bottom-24 right-4 w-96 h-[600px] bg-white rounded-lg shadow-xl flex flex-col border border-gray-200">
          <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-blue-500 text-white rounded-t-lg">
            <h3 className="font-bold">MaRCiA - AI Assistant</h3>
            <button
              onClick={handleClear}
              className="text-white hover:text-gray-200 transition-colors duration-200"
              aria-label="Clear chat history"
            >
              Clear Chat
            </button>
          </div>

          <div 
            ref={chatContainerRef}
            className="flex-1 overflow-y-auto p-4"
          >
            <div className="flex flex-col space-y-4">
              {reversedMessages.map((message, index) => (
                <div
                  key={message.id || index}
                  className={`flex ${
                    message.role === 'user' ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div
                    className={`max-w-[80%] p-3 rounded-lg ${
                      message.role === 'user'
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {renderMessageContent(message)}
                  </div>
                </div>
              ))}
              
              {isLoading && (
                <div className="flex justify-center">
                  <Loader className="animate-spin" size={24} />
                </div>
              )}
              
              {error && (
                <div className="text-red-500 text-center bg-red-50 p-2 rounded">
                  {error}
                </div>
              )}
              
              <div ref={messagesEndRef} />
            </div>
          </div>

          <form onSubmit={handleSubmit} className="p-4 border-t border-gray-200">
            <div className="flex space-x-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isLoading}
              />
              <button
                type="submit"
                disabled={isLoading || !input.trim()}
                className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 disabled:bg-gray-400"
                aria-label="Send message"
              >
                <Send size={20} />
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AIAssistant;
