import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Input } from './common';
import TextArea from './common/TextArea';
import EnhancedTextArea from './EnhancedTextArea';
import { useAssistant } from '../contexts/AssistantContext';
import { supabase } from '../supabaseClient';
import { theme } from '../styles/theme';

const ProblemStatementBuilder = ({ initialStatement, projectId }) => {
  const [problemStatement, setProblemStatement] = useState({
    full_statement: '',
    short_description: '',
    what: '',
    where: '',
    when: '',
    who: '',
    how: '',
    why: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');
  const [error, setError] = useState('');
  const { addAssistantMessage } = useAssistant();

  const placeholders = {
    what: [
      "What Equipment, Machine or Tool?",
      "What is wrong, What is the complaint?",
      "What Undesired Behavior is involved?"
    ].join('\n'),
    who: [
      "Who is involved (information, help, actions)?",
      "Who is affected?",
      "Who needs to be informed?"
    ].join('\n'),
    when: [
      "When it occurs: Date and Time",
      "What shift or operation phase?",
      "At what stage of plant/equipment life"
    ].join('\n'),
    where: [
      "Which unit, area, department?",
      "Location of the defect"
    ].join('\n'),
    how: [
      "How is the 'What' or 'Who' affected?",
      "Injury, Dead, Shutdown, Trip, Startup, Damage",
      "Type or Classification of Defect"
    ].join('\n'),
    why: [
      "Why has the problem arisen?",
      "Why is a solution needed?"
    ].join('\n'),
    full_statement: "Draft your full problem statement by compiling the info from the above fields. Click the 'sparkle' to suggest improvements"
  };

  useEffect(() => {
    if (initialStatement) {
      setProblemStatement(prevState => ({
        ...prevState,
        ...initialStatement
      }));
    }
    setIsLoading(false);
  }, [initialStatement]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProblemStatement(prev => ({ ...prev, [name]: value }));
  };

  const handleAIComment = (comment) => {
    addAssistantMessage(comment);
  };

  const handleShortDescriptionGenerated = (shortDescription) => {
    setProblemStatement(prev => ({
      ...prev,
      short_description: shortDescription
    }));
  };

  const generateFullStatement = useCallback(() => {
    const { what, where, when, who, how, why } = problemStatement;
    const generatedStatement = `The problem is ${what} occurring at ${where}. It was first noticed on ${when} and affects ${who}. The issue manifests by ${how}. This is significant because ${why}.`;
    setProblemStatement(prev => ({ ...prev, full_statement: generatedStatement }));
  }, [problemStatement]);

  const handleGenerateStatement = (e) => {
    e.preventDefault();
    generateFullStatement();
  };

  const handleSaveStatement = async (e) => {
    e.preventDefault();
    if (!projectId) return;

    setSaveStatus('Saving...');
    try {
      await supabase
        .from('problem_statements')
        .delete()
        .eq('project_id', projectId);

      const { error: insertError } = await supabase
        .from('problem_statements')
        .insert({
          ...problemStatement,
          project_id: projectId
        });

      if (insertError) throw insertError;

      setSaveStatus('Saved successfully');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving problem statement:', error);
      setSaveStatus('Error saving');
      setTimeout(() => setSaveStatus(''), 3000);
    }
  };

  if (isLoading) {
    return <div className="text-center py-4 text-gray-600">Loading...</div>;
  }

  return (
    <Card className="p-6 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {['what', 'where', 'when', 'who', 'how', 'why'].map((field) => (
          <div key={field} className="space-y-2">
            <label 
              htmlFor={field} 
              className="block text-sm font-semibold uppercase tracking-wide"
              style={{ color: theme.colors.primary.main }}
            >
              {field}
            </label>
            <TextArea
              id={field}
              name={field}
              value={problemStatement[field] || ''}
              onChange={handleInputChange}
              placeholder={placeholders[field]}
              rows={3}
            />
          </div>
        ))}
      </div>

      <div className="mb-6">
        <EnhancedTextArea
          label={
            <span className="text-lg font-semibold uppercase tracking-wide"
              style={{ color: theme.colors.primary.main }}>
              Full Problem Statement
            </span>
          }
          value={problemStatement.full_statement || ''}
          onChange={handleInputChange}
          name="full_statement"
          rows={4}
          placeholder={placeholders.full_statement}
          onAIComment={handleAIComment}
          onShortDescriptionGenerated={handleShortDescriptionGenerated}
        />
      </div>

      <div className="mb-6">
        <label 
          htmlFor="short_description" 
          className="block text-lg font-semibold uppercase tracking-wide mb-2"
          style={{ color: theme.colors.primary.main }}
        >
          Short Description
        </label>
        <Input
          id="short_description"
          name="short_description"
          value={problemStatement.short_description || ''}
          onChange={handleInputChange}
          placeholder="Enter a brief description of the problem"
        />
      </div>

      <div className="flex gap-4">
        <Button
          onClick={handleGenerateStatement}
          variant="primary"
          className="flex-1"
        >
          Generate Problem Statement
        </Button>
        <Button
          onClick={handleSaveStatement}
          variant="secondary"
          className="flex-1"
        >
          Save Problem Statement
        </Button>
      </div>

      {error && (
        <div className="px-4 py-3 rounded-lg relative mt-4 bg-red-50 border border-red-200 text-red-600">
          {error}
        </div>
      )}

      {saveStatus && (
        <div className={`mt-4 text-center px-4 py-2 rounded-lg border ${
          saveStatus.includes('Error')
            ? 'bg-red-50 border-red-200 text-red-600'
            : 'bg-emerald-50 border-emerald-200 text-emerald-600'
        }`}>
          {saveStatus}
        </div>
      )}
    </Card>
  );
};

export default ProblemStatementBuilder;
