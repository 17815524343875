import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Button, Card } from './common';
import FiveWhyAnalysisTool from './FiveWhyAnalysisTool';
import FishboneDiagramTool from './FishboneDiagramTool';
import ChartGeneratorTool from './ChartGeneratorTool';
import RootCauseClassification from './RootCauseClassification';
import Overview from './Overview';
import { theme } from '../styles/theme';

const Tab = ({ children, isActive, onClick }) => (
  <button
    className={`px-6 py-3 font-medium transition-all duration-200 rounded-lg
      ${isActive 
        ? `bg-[${theme.colors.primary.main}] text-[${theme.colors.text.light}] shadow-md` 
        : `bg-white text-[${theme.colors.text.secondary}] hover:bg-[${theme.colors.neutral.lighter}] 
           hover:text-[${theme.colors.primary.main}] hover:shadow-md transform hover:-translate-y-0.5`
      }`}
    onClick={onClick}
  >
    {children}
  </button>
);

const CausalFactorsTable = ({ title, factors }) => (
  <Card className="mb-8 p-6">
    <h3 className={`text-xl font-bold mb-6 text-[${theme.colors.primary.main}]`}>
      {title}
    </h3>
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className={`bg-[${theme.colors.neutral.light}]`}>
            <th className={`w-1/4 p-4 text-left font-semibold text-[${theme.colors.text.primary}] 
              border-b-2 border-[${theme.colors.border.main}]`}>Type</th>
            <th className={`w-3/4 p-4 text-left font-semibold text-[${theme.colors.text.primary}] 
              border-b-2 border-[${theme.colors.border.main}]`}>Cause Description</th>
          </tr>
        </thead>
        <tbody>
          <tr className={`hover:bg-[${theme.colors.neutral.lighter}] transition-colors`}>
            <td className={`w-1/4 p-4 font-semibold text-[${theme.colors.primary.main}] 
              border-b border-[${theme.colors.border.light}]`}>Root Causes</td>
            <td className={`w-3/4 p-4 border-b border-[${theme.colors.border.light}]`}>
              {factors.root.length > 0 ? 
                factors.root.join(', ') : 
                <span className={`text-[${theme.colors.text.secondary}] italic`}>No root causes identified</span>
              }
            </td>
          </tr>
          <tr className={`hover:bg-[${theme.colors.neutral.lighter}] transition-colors`}>
            <td className={`w-1/4 p-4 font-semibold text-[${theme.colors.primary.main}] 
              border-b border-[${theme.colors.border.light}]`}>Contributing Causes</td>
            <td className={`w-3/4 p-4 border-b border-[${theme.colors.border.light}]`}>
              {factors.contributing.length > 0 ? 
                factors.contributing.join(', ') : 
                <span className={`text-[${theme.colors.text.secondary}] italic`}>No contributing causes identified</span>
              }
            </td>
          </tr>
          <tr className={`hover:bg-[${theme.colors.neutral.lighter}] transition-colors`}>
            <td className={`w-1/4 p-4 font-semibold text-[${theme.colors.primary.main}]`}>Possible Causes</td>
            <td className="w-3/4 p-4">
              {factors.possible.length > 0 ? 
                factors.possible.join(', ') : 
                <span className={`text-[${theme.colors.text.secondary}] italic`}>No possible causes identified</span>
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
);

const RootCauseIdentification = ({ projectId }) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [problemStatement, setProblemStatement] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [fiveWhysCausalFactors, setFiveWhysCausalFactors] = useState(null);
  const [fishboneCausalFactors, setFishboneCausalFactors] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!projectId) return;

      setIsLoading(true);
      try {
        const { data: problemData } = await supabase
          .from('problem_statements')
          .select('full_statement')
          .eq('project_id', projectId)
          .maybeSingle();

        setProblemStatement(problemData?.full_statement || '');

        if (problemData?.full_statement) {
          // Fetch Five Whys data
          const { data: whysData } = await supabase
            .from('five_whys_analysis')
            .select('whys')
            .eq('project_id', projectId)
            .maybeSingle();
          
          if (whysData?.whys) {
            const factors = {
              root: [],
              contributing: [],
              possible: []
            };

            whysData.whys.forEach(why => {
              if (why.isRoot === 'Yes' || why.isRoot === true) {
                if (why.contributing) {
                  const contributingCauses = why.contributing.split(',')
                    .map(cause => cause.trim())
                    .filter(cause => cause.length > 0);
                  factors.contributing.push(...contributingCauses);
                }
                if (why.possible) {
                  const possibleCauses = why.possible.split(',')
                    .map(cause => cause.trim())
                    .filter(cause => cause.length > 0);
                  factors.possible.push(...possibleCauses);
                }
                if (why.because) {
                  factors.root.push(why.because.trim());
                }
              }
            });

            if (factors.root.length > 0 || factors.contributing.length > 0 || factors.possible.length > 0) {
              setFiveWhysCausalFactors(factors);
            }
          }

          // Fetch Fishbone data
          const { data: fishboneData } = await supabase
            .from('fishbone_diagrams')
            .select('data')
            .eq('project_id', projectId)
            .maybeSingle();
          
          if (fishboneData?.data?.cause_classifications) {
            const classifications = fishboneData.data.cause_classifications;
            if (classifications.root.length > 0 || 
                classifications.contributing.length > 0 || 
                classifications.possible.length > 0) {
              setFishboneCausalFactors(classifications);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return <Overview />;
      case '5whys':
        return <FiveWhyAnalysisTool projectId={projectId} />;
      case 'fishbone':
        return <FishboneDiagramTool projectId={projectId} />;
      case 'charts':
        return <ChartGeneratorTool />;
      case 'classification':
        return (
          <div>
            {fiveWhysCausalFactors && (
              <CausalFactorsTable 
                title="Five Whys Analysis - Causal Factors" 
                factors={fiveWhysCausalFactors} 
              />
            )}
            {fishboneCausalFactors && (
              <CausalFactorsTable 
                title="Fishbone Diagram - Causal Factors" 
                factors={fishboneCausalFactors} 
              />
            )}
            <RootCauseClassification projectId={projectId} />
          </div>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div className={`text-center py-4 text-[${theme.colors.text.secondary}]`}>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <h2 className={`text-3xl font-bold text-[${theme.colors.primary.main}]`}>
        Root Cause Identification
      </h2>
      
      <Card className="p-6">
        <h3 className={`text-lg font-semibold text-[${theme.colors.primary.main}] mb-4`}>Problem Statement:</h3>
        {problemStatement ? (
          <p className={`text-[${theme.colors.text.secondary}] leading-relaxed`}>{problemStatement}</p>
        ) : (
          <p className={`text-[${theme.colors.error.main}] font-medium`}>
            Problem not defined. Please complete the Problem Definition step first.
          </p>
        )}
      </Card>

      {problemStatement && (
        <Card className="overflow-hidden">
          <div className={`flex space-x-2 p-4 bg-white border-b border-[${theme.colors.border.light}]`}>
            {['overview', '5whys', 'fishbone', 'charts', 'classification'].map((tab) => (
              <Tab
                key={tab}
                isActive={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab === 'overview' ? 'Overview' : 
                 tab === '5whys' ? '5 Whys' :
                 tab === 'fishbone' ? 'Fishbone' :
                 tab === 'charts' ? 'Chart Generation' : 'Classification'}
              </Tab>
            ))}
          </div>
          <div className="p-6">
            {renderTabContent()}
          </div>
        </Card>
      )}
    </div>
  );
};

export default RootCauseIdentification;
