import React, { useState, useEffect } from 'react';
import { Button, Card } from './common';
import ProjectInitialization from './ProjectInitialization';
import ProjectList from './ProjectList';
import ProblemDefinition from './ProblemDefinition';
import RootCauseIdentification from './RootCauseIdentification';
import CorrectiveActionPlan from './CorrectiveActionPlan';
import ReportGenerator from './ReportGenerator';
import SignIn from './SignIn';
import Registration from './Registration';
import AIAssistant from './AIAssistant';
import Glossary from './Glossary';
import { supabase, checkSupabaseConnection } from '../supabaseClient';
import { theme } from '../styles/theme';
import { Book } from 'lucide-react';

const Tab = ({ children, isActive, onClick, disabled }) => (
  <button
    className={`px-6 py-3 rounded-lg transition-all duration-200 flex-grow text-sm md:text-base font-medium
    ${!disabled && !isActive && 'hover:shadow-md transform hover:-translate-y-0.5'}`}
    onClick={onClick}
    disabled={disabled}
    style={
      isActive 
        ? {
            background: `linear-gradient(to right, ${theme.colors.primary.main}, ${theme.colors.primary.hover})`,
            color: theme.colors.text.light,
            boxShadow: theme.shadows.md
          }
        : disabled 
          ? {
              backgroundColor: theme.colors.neutral.light,
              color: theme.colors.neutral.main,
              cursor: 'not-allowed'
            }
          : {
              backgroundColor: 'white',
              color: theme.colors.text.secondary,
              ':hover': {
                backgroundColor: theme.colors.neutral.lighter,
                color: theme.colors.primary.main
              }
            }
    }
  >
    {children}
  </button>
);

const NoProjectMessage = () => (
  <Card className="text-center py-8">
    <div className={`text-lg text-[${theme.colors.text.secondary}] mb-4`}>
      Please create or select a project first
    </div>
    <div className={`text-sm text-[${theme.colors.text.secondary}]`}>
      Use the Project Initialization tab to get started
    </div>
  </Card>
);

const RootCauseAnalysisPro = () => {
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab || 'project-init';
  });

  const [project, setProject] = useState(() => {
    const savedProject = localStorage.getItem('currentProject');
    return savedProject ? JSON.parse(savedProject) : null;
  });

  const [pageContext, setPageContext] = useState({
    problemStatement: '',
    currentStep: '',
    formData: null
  });

  const [user, setUser] = useState(null);
  const [isSupabaseConnected, setIsSupabaseConnected] = useState(false);
  const [connectionError, setConnectionError] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);

  const mainTabs = [
    { id: 'project-init', label: 'Project Initialization' },
    { id: 'problem-definition', label: 'Problem Definition' },
    { id: 'root-cause-identification', label: 'Root Cause Identification' },
    { id: 'corrective-action-plan', label: 'Corrective Action Plan' }
  ];

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (project) {
      localStorage.setItem('currentProject', JSON.stringify(project));
    } else {
      localStorage.removeItem('currentProject');
    }
  }, [project]);

  useEffect(() => {
    const checkConnection = async () => {
      const isConnected = await checkSupabaseConnection();
      setIsSupabaseConnected(isConnected);
      if (!isConnected) {
        setConnectionError('Unable to connect to the database. Please check your internet connection and try again.');
      }
    };
    checkConnection();
  }, []);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      const currentUser = session?.user || null;
      setUser(currentUser);

      if (!currentUser) {
        localStorage.removeItem('activeTab');
        localStorage.removeItem('currentProject');
        setProject(null);
        setActiveTab('project-init');
      }
    };
    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        const currentUser = session?.user || null;
        setUser(currentUser);

        if (event === 'SIGNED_OUT') {
          localStorage.removeItem('activeTab');
          localStorage.removeItem('currentProject');
          setProject(null);
          setActiveTab('project-init');
        }
      }
    );

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  const updatePageContext = (newContext) => {
    setPageContext(prev => ({
      ...prev,
      ...newContext
    }));
  };

  const handleProjectInit = (newProject) => {
    setProject(newProject);
    setActiveTab('problem-definition');
    updatePageContext({
      currentStep: 'problem-definition',
      formData: null,
      problemStatement: ''
    });
  };

  const handleProjectSelect = (selectedProject) => {
    setProject(selectedProject);
    setActiveTab('problem-definition');
    updatePageContext({
      currentStep: 'problem-definition',
      formData: null,
      problemStatement: ''
    });
  };

  const handleSignIn = (user) => {
    setUser(user);
    setActiveTab('project-init');
  };

  const handleRegister = (user) => {
    setUser(user);
    setActiveTab('project-init');
    setShowRegistration(false);
  };

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
    setProject(null);
    setActiveTab('project-init');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('currentProject');
  };

  const handleTabChange = (tabId) => {
    if (tabId === 'project-init' || project) {
      setActiveTab(tabId);
    }
  };

  const shouldShowGlossary = () => {
    if (!user) return false;
    if (activeTab === 'project-init' && !project) return false;
    if (showReport) return false;
    return true;
  };

  const renderContent = () => {
    if (!user) {
      return showRegistration ? (
        <Registration onRegister={handleRegister} onCancel={() => setShowRegistration(false)} />
      ) : (
        <SignIn onSignIn={handleSignIn} onRegisterClick={() => setShowRegistration(true)} />
      );
    }

    if (showReport && project) {
      return <ReportGenerator projectId={project.id} updatePageContext={updatePageContext} />;
    }

    if (!project && activeTab !== 'project-init') {
      return <NoProjectMessage />;
    }

    const commonProps = {
      updatePageContext: updatePageContext
    };

    switch (activeTab) {
      case 'project-init':
        return (
          <div className="space-y-8">
            <ProjectInitialization 
              onProjectInit={handleProjectInit}
              {...commonProps}
            />
            <ProjectList 
              onProjectSelect={handleProjectSelect}
              onFetchError={(error) => setConnectionError(error)}
              {...commonProps}
            />
          </div>
        );
      case 'problem-definition':
        return (
          <ProblemDefinition 
            projectId={project.id}
            {...commonProps}
          />
        );
      case 'root-cause-identification':
        return (
          <RootCauseIdentification 
            projectId={project.id}
            {...commonProps}
          />
        );
      case 'corrective-action-plan':
        return (
          <CorrectiveActionPlan 
            projectId={project.id}
            {...commonProps}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 relative min-h-screen pb-16">
      <header className="bg-white shadow-lg rounded-b-2xl py-6 mb-8" style={{ borderBottom: `1px solid ${theme.colors.border.light}` }}>
        {/* App Title and Sign Out */}
        <div className="flex justify-between items-center px-6 mb-6">
          <div className="flex items-center space-x-6">
            <img 
              src="/logo.png" 
              alt="Aviation Synergy Logo" 
              className="h-20 w-auto"
            />
            <div className="text-3xl font-bold" style={{ color: theme.colors.primary.main }}>
              Root Cause Analysis Pro
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            {user && (
              <>
                <Button
                  onClick={handleSignOut}
                  variant="accent"
                >
                  Sign Out
                </Button>
                <div className="text-sm font-medium" style={{ color: theme.colors.text.secondary }}>
                  Logged in as: <span style={{ color: theme.colors.primary.main }}>{user.email}</span>
                </div>
              </>
            )}
          </div>
        </div>

        {connectionError && (
          <div className="mb-4 px-6" style={{ color: theme.colors.error.main }}>
            {connectionError}
          </div>
        )}

        {/* Project Info and Generate Report */}
        {project && (
          <div className="px-6 mb-6">
            <div className="flex justify-between items-center p-4 rounded-lg" style={{ backgroundColor: theme.colors.background.paper }}>
              <div className="space-y-1">
                <div className="flex items-center space-x-2">
                  <span className="font-semibold" style={{ color: theme.colors.text.secondary }}>Project:</span>
                  <span className="font-bold" style={{ color: theme.colors.primary.main }}>{project.project_name}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-sm" style={{ color: theme.colors.text.secondary }}>ID:</span>
                  <span className="text-sm font-medium" style={{ color: theme.colors.primary.main }}>{project.project_id}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-sm" style={{ color: theme.colors.text.secondary }}>Team:</span>
                  <span className="text-sm font-medium" style={{ color: theme.colors.primary.main }}>
                    {Array.isArray(project.team_members) ? project.team_members.join(', ') : project.team_members}
                  </span>
                </div>
              </div>
              {user && project && (
                <Button
                  onClick={() => setShowReport(!showReport)}
                  variant="secondary"
                  className="ml-4"
                >
                  {showReport ? 'Back to Editor' : 'Generate Report'}
                </Button>
              )}
            </div>
          </div>
        )}

        {/* Navigation Tabs */}
        {user && !showReport && (
          <nav className="flex flex-wrap gap-2 px-6">
            {mainTabs.map((tab) => (
              <Tab
                key={tab.id}
                isActive={activeTab === tab.id}
                onClick={() => handleTabChange(tab.id)}
                disabled={!project && tab.id !== 'project-init'}
              >
                {tab.label}
              </Tab>
            ))}
          </nav>
        )}
      </header>
      
      <main className="bg-white border rounded-xl shadow-lg p-8 mb-8" style={{ borderColor: theme.colors.border.light }}>
        {isSupabaseConnected ? renderContent() : <p>Connecting to the database...</p>}
      </main>

      {user && project && !showReport && (
        <AIAssistant 
          activeTab={activeTab}
          pageContext={pageContext}
          project={project}
        />
      )}

      {shouldShowGlossary() && (
        <Button
          onClick={() => setIsGlossaryOpen(true)}
          className="fixed bottom-4 left-4"
          variant="primary"
          icon={Book}
        >
          Glossary
        </Button>
      )}

      <Glossary isOpen={isGlossaryOpen} onClose={() => setIsGlossaryOpen(false)} />
    </div>
  );
};

export default RootCauseAnalysisPro;
