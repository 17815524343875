import React, { useState } from 'react';
import { Sparkles } from 'lucide-react';
import OpenAI from 'openai';

// Initialize OpenAI client with v2 beta header
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
  defaultHeaders: {
    'OpenAI-Beta': 'assistants=v2'
  }
});

const EnhancedTextArea = ({ 
  value, 
  onChange, 
  label,
  name = 'full_statement',
  placeholder = '',
  rows = 4,
  className = '',
  onAIComment,
  onShortDescriptionGenerated
}) => {
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [error, setError] = useState('');

  const enhanceText = async (e) => {
    e.preventDefault();
    
    if (!value.trim()) {
      setError('Please enter some text before enhancing');
      return;
    }

    try {
      setIsEnhancing(true);
      setError('');

      const thread = await openai.beta.threads.create();
      
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: `Please improve the structure and clarity of this problem statement. 
          IMPORTANT RULES:
          - Only use information explicitly provided in the original text
          - Do not add any new details or assumptions
          - Focus on improving clarity, grammar, and organization
          - Maintain all original facts and specifics exactly as provided
          
          Provide your response in three parts:
          1. ENHANCED_TEXT: The restructured problem statement
          2. SHORT_DESCRIPTION: A brief title of the core problem, issue or event. For example,"aircraft collision".
          3. COMMENTS: Brief explanation of the structural improvements made
          
          Original text: ${value}`
      });

      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: process.env.REACT_APP_OPENAI_ASSISTANT_ID,
        instructions: "You are helping to structure problem statements. Only use information explicitly provided by the user. Never add new details or make assumptions. Focus solely on improving clarity and organization of the existing content."
      });

      let runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      while (runStatus.status !== 'completed') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        
        if (runStatus.status === 'failed') {
          throw new Error('Enhancement failed');
        }
      }

      const messages = await openai.beta.threads.messages.list(thread.id);
      const response = messages.data[0].content[0].text.value;
      
      // Extract enhanced text, short description, and comments
      const enhancedMatch = response.match(/ENHANCED_TEXT:(.*?)(?=SHORT_DESCRIPTION:|$)/s);
      const shortDescMatch = response.match(/SHORT_DESCRIPTION:(.*?)(?=COMMENTS:|$)/s);
      const commentsMatch = response.match(/COMMENTS:(.*?)$/s);
      
      const enhancedText = enhancedMatch ? enhancedMatch[1].trim() : '';
      const shortDescription = shortDescMatch ? shortDescMatch[1].trim() : '';
      const comments = commentsMatch ? commentsMatch[1].trim() : '';

      if (!enhancedText) {
        throw new Error('Failed to generate enhanced text');
      }

      // Update the text field with enhanced text
      onChange({
        target: {
          name,
          value: enhancedText
        }
      });

      // Update short description if callback is provided
      if (shortDescription && onShortDescriptionGenerated) {
        onShortDescriptionGenerated(shortDescription);
      }

      // Send comments to AI Assistant chat
      if (comments && onAIComment) {
        onAIComment(
          "Here are the structural improvements made to your problem statement:\n\n" + 
          comments
        );
      }

    } catch (err) {
      console.error('Error enhancing text:', err);
      setError('Failed to enhance text. Please try again.');
    } finally {
      setIsEnhancing(false);
    }
  };

  return (
    <div className="relative group">
      {label && (
        <div className="block mb-2">
          {label}
        </div>
      )}
      <div className="relative">
        <textarea
          value={value}
          onChange={onChange}
          name={name}
          rows={rows}
          className={`w-full px-4 py-3 rounded-lg border-2 border-gray-200 bg-white shadow-sm 
            focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 
            hover:border-indigo-300 transition-all duration-200
            placeholder-gray-400 text-gray-700 ${className}`}
          placeholder={placeholder}
        />
        <button
          onClick={enhanceText}
          disabled={isEnhancing}
          className={`absolute right-3 top-3 p-2 rounded-lg 
            bg-gradient-to-r from-indigo-50 to-indigo-100
            hover:from-indigo-100 hover:to-indigo-200
            transition-all duration-200 group
            ${isEnhancing ? 'opacity-50 cursor-not-allowed' : ''}`}
          title="Enhance text with AI"
          type="button"
        >
          <Sparkles 
            size={24} 
            className={`text-indigo-600 transform group-hover:scale-110 transition-transform
              ${isEnhancing ? 'animate-pulse' : ''}`} 
          />
        </button>
      </div>
      {error && (
        <p className="mt-2 text-sm font-medium text-red-600">{error}</p>
      )}
    </div>
  );
};

export default EnhancedTextArea;
