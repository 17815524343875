import React from 'react';
import { FileText, GitBranch, BarChart2, Tags } from 'lucide-react';
import { Card } from './common';
import { theme } from '../styles/theme';

const ToolCard = ({ icon: Icon, title, description }) => (
  <Card className="p-6 hover:shadow-lg transition-shadow duration-200">
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <div style={{ background: `linear-gradient(to bottom right, ${theme.colors.primary.main}, ${theme.colors.primary.hover})` }}
          className="p-3 rounded-lg text-white">
          <Icon size={24} />
        </div>
      </div>
      <div className="flex-grow">
        <h4 style={{ color: theme.colors.text.primary }} className="text-lg font-semibold mb-2">{title}</h4>
        <p style={{ color: theme.colors.text.secondary }} className="leading-relaxed">{description}</p>
      </div>
    </div>
  </Card>
);

const Overview = () => {
  const tools = [
    {
      icon: FileText,
      title: "5 Whys Analysis",
      description: "A systematic technique for exploring cause-and-effect relationships underlying a particular problem. Helps identify the root cause by repeatedly asking 'why' the problem occurs."
    },
    {
      icon: GitBranch,
      title: "Fishbone Diagram",
      description: "Also known as the Ishikawa diagram, this visual tool helps identify and categorize potential causes of a problem, organizing them into distinct categories for comprehensive analysis."
    },
    {
      icon: BarChart2,
      title: "Chart Generation",
      description: "Create visual representations of data through Pareto charts and histograms. These charts help identify the most significant factors and patterns in your analysis."
    },
    {
      icon: Tags,
      title: "Root Cause Classification",
      description: "Systematically categorize and organize identified root causes to understand patterns and common issues, facilitating more effective problem-solving strategies."
    }
  ];

  return (
    <div className="space-y-8">
      <Card className="p-6">
        <h3 style={{ color: theme.colors.primary.main }} className="text-xl font-semibold mb-4">
          Overview
        </h3>
        <p style={{ color: theme.colors.text.secondary }} className="leading-relaxed">
          Root Cause Identification tools help in systematically analyzing problems to identify their underlying causes. 
          Use these comprehensive tools to conduct a thorough analysis and develop effective solutions.
        </p>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {tools.map((tool, index) => (
          <ToolCard key={index} {...tool} />
        ))}
      </div>

      <Card className="p-6">
        <h3 style={{ color: theme.colors.primary.main }} className="text-xl font-semibold mb-4">
          Getting Started
        </h3>
        <div className="space-y-4">
          <p style={{ color: theme.colors.text.secondary }} className="leading-relaxed">
            Begin your analysis by selecting the most appropriate tool for your needs:
          </p>
          <ul className="space-y-3">
            <li className="flex items-start space-x-3">
              <div style={{ backgroundColor: theme.colors.primary.main }} 
                className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full"></div>
              <span style={{ color: theme.colors.text.secondary }}>
                Use <span style={{ color: theme.colors.text.primary }} className="font-medium">5 Whys</span> for straightforward problems where you need to dig deeper into the cause-and-effect chain.
              </span>
            </li>
            <li className="flex items-start space-x-3">
              <div style={{ backgroundColor: theme.colors.primary.main }} 
                className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full"></div>
              <span style={{ color: theme.colors.text.secondary }}>
                Choose the <span style={{ color: theme.colors.text.primary }} className="font-medium">Fishbone Diagram</span> for complex problems with multiple potential causes across different categories.
              </span>
            </li>
            <li className="flex items-start space-x-3">
              <div style={{ backgroundColor: theme.colors.primary.main }} 
                className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full"></div>
              <span style={{ color: theme.colors.text.secondary }}>
                Utilize <span style={{ color: theme.colors.text.primary }} className="font-medium">Chart Generation</span> to visualize data patterns and identify the most significant factors.
              </span>
            </li>
            <li className="flex items-start space-x-3">
              <div style={{ backgroundColor: theme.colors.primary.main }} 
                className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full"></div>
              <span style={{ color: theme.colors.text.secondary }}>
                Use <span style={{ color: theme.colors.text.primary }} className="font-medium">Root Cause Classification</span> to organize and categorize your findings for better understanding and action planning.
              </span>
            </li>
          </ul>
        </div>
      </Card>
    </div>
  );
};

export default Overview;
